import fetchData from '../utils/fetchData';

const group = 'GENERALS';

function getDashboard(params) {
  return fetchData({
    url: '/taxonomy/get-status-count-list',
    method: 'GET',
    params: params,
    group,
  });
}

export {getDashboard}
