import React, {useState} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

// context
import {defaultState, utilsContext} from './contexts';

// component
import Router from './Router';
import Backdrop from './components/Backdrop';
import Snackbar from './components/Snackbar';

// schema theme, font, ... at here
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024,
      xl: 1440,
    }
  },
  overrides: {
    MuiFilledInput: {
      root: {
        borderRadius: 10,
        border: `1px solid green`,
        backgroundColor: 'red',
      },
      multiline: {
        '&$marginDense': {
          paddingTop: 6,
        }
      },
    },
    MuiOutlinedInput: {
      root: {

      }
    }
  },
  typography: {
    fontFamily: 'GillSans',
    fontSize: 14,
    titleText: {
      fontSize: 16,
    },
    navItem: {
      fontSize: 15,
    },
    pageTitleText: {
      fontSize: 22,
    },
    sectionTitleText: {
      fontSize: 20,
    },
    textFieldLabel: {
      fontSize: 12,
      fontWeight: 600,
    },
    texFieldValue: {
      fontWeight: 500,
    },
  },
  shadows: Array(25).fill('none'),
  inputs: {
    width: 189
  },
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#000000',
      others: '#4c4e55',
      fontSize: 25
    },
    secondary: {
      main: '#007dc3',
      contrastText: '#fff',
      ascent: 'rgb(177 177 177)',
      fontSize: 18,
    },
    background: {
      default: '#f6f8fc',
      section: '#ffffff',
      selected: '#dddddd4D',
      row: '#f6f8fc',
      border: '#dddddd',
      active: '#00000094',
      disabled: '#d8d8d8',
      fixed: '#fd7170',
      navBar: 'linear-gradient(to bottom, #03549b, #0d88da)',
      navItem: '#7d8fad',
      selectInput: 'linear-gradient(to right, #007dc3, #0d88da)',
      icon: '#a2abb9',
      button: '#1e86ff',
    },
  },
});

function App() {
  // add context provider at here
  const [utilsContextState, setUtilsContext] = useState(defaultState);

  const handleUtilsContextChange = (newState) => {
    setUtilsContext((prevState) => {
      return {
        ...prevState,
        ...newState,
      };
    });
  };

  return (
    <utilsContext.Provider
      value={{
        ...utilsContextState,
        _handleChange: handleUtilsContextChange
      }}
    >
      <MuiThemeProvider theme={theme}>
        <Backdrop
          open={utilsContextState.openBackdrop}
          handleOpen={handleUtilsContextChange}
        />
        <Snackbar
          open={utilsContextState.snackbar}
          type={utilsContextState.snackbarType}
          content={utilsContextState.snackbarContent}
          handleOpen={handleUtilsContextChange}
        />
        <Router />
      </MuiThemeProvider>
    </utilsContext.Provider>
  );
}

export default App;
