import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import Search from '@material-ui/icons/Search';
import {Grid, Button} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CustomizedButtons from './InputFields/CustomizedButtons';

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells, itemAlignLeft } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.headerWrapper}>
      <TableRow>
        {headCells.map((headCell, index) => {
          if (headCell.label == 'HIDE') return null;
          return(
              <TableCell
                key={headCell.id + index}
                align={itemAlignLeft ? 'left' : 'center'}
                className={clsx(
                  classes.tableCell, classes.headerText,
                  (index !== 0 && index !== headCells.length-1) && classes.tableCellMinWidth,
                )}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  className={clsx(classes.headerLabel, !headCell.disabledSort && classes.sortLabel)}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={headCell.disabledSort ? () => {} : createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {!headCell.disabledSort && orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableBody = props => {
  const { rows, page, rowsPerPage, emptyRows, bodyHover, classes, handleRowClick, itemAlignLeft } = props;

  return (
    <TableBody>
      {rows
        .map((row, index) => {
          return (
            <TableRow
              className={clsx(classes.rowsGroup, (index % 2) === 0 ? classes.tableRowOdd : classes.tableRowEven)}
              hover={!bodyHover}
              role="checkbox"
              tabIndex={-1}
              key={row.id ? index + row.id : index}
            >
              {Object.keys(row).map((key, subIndex) => {
                if (key == 'real_id') {return null}
                return (
                  <TableCell
                    className={clsx(classes.tableCell, (subIndex !== 0 && subIndex !== Object.keys(row).length-1) && classes.tableCellMinWidth)}
                    key={`${row.name}-${subIndex}`}
                    onClick={(Object.keys(row).length-1 !== subIndex) ? handleRowClick(row.real_id): () => {}}
                    align={itemAlignLeft ? "left" : "center"}>
                    {row[key]}
                  </TableCell>
                )
              })}
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow>
          <TableCell colSpan={6} className={classes.tableCell} />
        </TableRow>
      )}
    </TableBody>
  )
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 375
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    // padding: 15,
    textAlign: 'end',
  },
  table: {
    minWidth: 750,
  },
  noMinWidth: {
    minWidth: 'auto',
  },
  rowsGroup: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.background.border,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    }
  },
  tableRowOdd: {
    backgroundColor: theme.palette.background.row,
  },
  tableRowEven: {
    backgroundColor: theme.palette.primary.main,
  },
  tableCell: {
    padding: '0px 10px',
    color: theme.palette.primary.contrastText,
    border: 0,
    fontSize: 12,
    '& .MuiTableSortLabel-icon': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.background.button
    }
  },
  tableCellMinWidth: {
    minWidth: 69,
  },
  headerWrapper: {
    backgroundColor: theme.palette.background.navBar
  },
  headerText: {
    color: theme.palette.primary.contrastText,
    lineHeight: 'initial',
    borderBottom: '2px solid #b1b1b1',
  },
  headerLabel: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
    },
  },
  sortLabel: {
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: theme.palette.secondary.main
    },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: theme.palette.secondary.main
    },
    '&:hover': {
      color: theme.palette.secondary.main
    },
    '&:focus': {
      color: theme.palette.secondary.main
    }
  },
  headText: {
    width: 'max-content',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pageWrapper: {
    paddingBottom: 10,
    paddingRight: 20,
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noDataContent: {
    margin: 13,
    textAlign: 'start',
    fontSize: theme.typography.textFieldLabel.fontSize,
  },
  rowsPerPageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  rowPerPageLabel: {
    marginRight: 10,
    fontSize: theme.typography.textFieldLabel.fontSize,
  },
  rowPerPageSelect: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    '& .MuiSelect-root': {
      padding: '5px 30px 5px 10px',
    }
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const {
    headCells, rows, page, pageItems, rowsPerPage, showRowSelection, showRowSelectionTextOnly,
    order, orderBy, setState, noMinWidth, bodyHover, isListPagination,
    search, setSearch, handleRowClick=()=> {}, itemAlignLeft
  } = props;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  };

  const handleSearch = () => {
    setState({ searchValue: search })
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let newOrder = isAsc ? 'desc' : 'asc';
    setState({ order: newOrder, orderBy: property });
  };

  const handleChangePage = (event, newPage) => {
    setState({ page: newPage })
  };

  const handleChangePageList = event => {
    setState({ page: parseInt(event.target.value, 10) });
  };

  const handleChangeRowsPerPage = event => {
    setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handlePreviousPage = (page) => {
    setState({ page: page - 1});
  }

  const handleNextPage = (page) => {
    setState({ page: page + 1});
  }

  let pageListArr = [];
  let pageItemIndex = Math.ceil(pageItems/rowsPerPage);
  for (let index = 0; index < pageItemIndex; index++) {
    pageListArr.push(index+1);
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer classes={{ root: classes.tableWrapper }}>
          {setSearch &&
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">Search Name</InputLabel>
              <Input
                id="search-box"
                type="text"
                value={search}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      onClick={handleSearch}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          }

          {rows.length === 0 ?
            <Typography className={classes.noDataContent}>No Data Found</Typography>
            :
            <Table
              className={clsx(classes.table, noMinWidth && classes.noMinWidth)}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                itemAlignLeft={itemAlignLeft}
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
              <EnhancedTableBody
                bodyHover={bodyHover}
                itemAlignLeft={itemAlignLeft}
                classes={classes}
                rows={rows}
                headCells={headCells}
                page={page}
                rowsPerPage={rowsPerPage}
                handleRowClick={handleRowClick}
                // emptyRows={emptyRows}
              />
            </Table>
          }
        </TableContainer>
        <Grid
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={4} className={classes.rowsPerPageWrapper}>
            {showRowSelection &&
              <React.Fragment>
                <Typography className={classes.rowPerPageLabel}>Item per page</Typography>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={rowsPerPage}
                    className={classes.rowPerPageSelect}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
            }
            {showRowSelectionTextOnly &&
              <React.Fragment>
                <Typography className={classes.rowPerPageLabel}>Displaying {(rowsPerPage * (page-1)) + 1} to {rowsPerPage*page} (total {pageItems} records)</Typography>
              </React.Fragment>
            }
          </Grid>
          <Grid item xs={12} md={6}>
            {
              (pageItems > rowsPerPage) ?
                isListPagination ?
                  <div className={classes.rowsPerPageWrapper} style={{justifyContent: 'flex-end'}}>
                    {
                      page > 1 && <CustomizedButtons label="Previous" handleClick={() => handlePreviousPage(page)}/>
                    }
                    <Typography className={classes.rowPerPageLabel} style={{alignSelf: 'center'}}>Page select</Typography>
                    <FormControl variant="outlined" >
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={page}
                        className={classes.rowPerPageSelect}
                        onChange={handleChangePageList}
                      >
                        {
                          pageListArr.map((i) => {
                            return <MenuItem value={i}>{i}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                    {
                      page != Math.ceil(pageItems/rowsPerPage) && <CustomizedButtons label="Next" handleClick={() => handleNextPage(page)}/>
                    }
                  </div>
                :
                  <div className={classes.pageWrapper}>
                  <Pagination
                    count={ Math.ceil(pageItems/rowsPerPage)}
                    variant="outlined"
                    color="secondary"
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              : null
            }
          </Grid>
        </Grid>

        {/*<TablePagination*/}
        {/*  rowsPerPageOptions={[]}*/}
        {/*  component="div"*/}
        {/*  count={pageItems}*/}
        {/*  rowsPerPage={rowsPerPage}*/}
        {/*  page={page}*/}
        {/*  onChangePage={handleChangePage}*/}
        {/*  // onChangeRowsPerPage={handleChangeRowsPerPage}*/}
        {/*/>*/}
      </Paper>
    </div>
  );
}
