import React, { useContext, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useDropzone} from 'react-dropzone';
import Zoom from 'react-medium-image-zoom'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

// components
import { utilsContext } from '../../../contexts';
import {FILE_UPLOAD_SIZE} from '../../../configs';
import MultilineTextField from '../../../components/InputFields/MultilineTextField.js';

// material ui
import {
  Typography,
  Grid,
  Paper, Container,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    marginBottom: 30,
  },
  sectionTitle: {
    // textAlign: 'end',
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    fontSize: theme.typography.textFieldLabel.fontSize,
  },
  addImageBoxWrapper: {
    backgroundColor: '#8080802e',
    boxShadow: '1px 2px 7px #9e9e9e54',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 100,
    alignItems: 'center',
  },
  imageBoxWrapper: {
    backgroundColor: '#8080802e',
    borderRadius: 10,
    boxShadow: '1px 2px 7px #9e9e9e54',
    position: 'relative',
  },
  zoomWrapper: {
    '& div': {
      width: '100%',
    }
  },
  image: {
    width: '100%',
    objectFit: 'scale-down',
    height: 100,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    maxWidth: '100%',
    // height: '-webkit-fill-available',
  },
  imageDetails: {
   padding: 10,
  },
  closeButton: {
    zIndex: 3,
    position: 'absolute',
    right: -20,
    top: -20,
  },
  closeIcon: {
    color: '#ff3e3e',
    fontSize: 25,
  }
}));

function PreDiagnose(props) {
  const {mainState, setMainState} = props;
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const handleRemovePhotos = (index, items) => () => {
    const tmpArr = [...mainState.files];
    const tmpCreateArr = [...mainState.createFiles];
    const deleteTmpArr = [...mainState.deleteFiles];

    for (let i = 0; i < tmpArr.length; i++) {
      if (tmpArr[i].id && tmpArr[i].id === items.id) {
        deleteTmpArr.push(items.id)
        break;
      }
    }

    for (let i = 0; i < mainState.createFiles.length; i++) {
      if (mainState.createFiles[i].path && mainState.createFiles[i].path === items.path) {
        tmpCreateArr.splice(i, 1);
        break;
      }
    }

    tmpArr.splice(index, 1);
    setMainState({ files: tmpArr, deleteFiles: deleteTmpArr, createFiles: tmpCreateArr });
  }

  const handleChangeDynamic = useCallback(index => key => event => {
    const tmpArr = [...mainState.files];
    tmpArr[index][key] = event.target.value;
    setMainState({ files: tmpArr });
  }, [mainState.files]);

  // DropZone functions
  const handleOnDrop = acceptedFiles => {
    if (!acceptedFiles.length) return;
    const tmpFiles = [...mainState.files];
    const tmpCreateFiles = [...mainState.createFiles];
    const tmpData = acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
      remarks: ''
    }))

    tmpFiles.push(tmpData[0])
    tmpCreateFiles.push(tmpData[0])
    setMainState({
      files: tmpFiles,
      createFiles: tmpCreateFiles,
    })
  };

  const handleDropRejected = (files) => {
    if (!files.length) return;
    const file = files[0];
    if (file.size >= FILE_UPLOAD_SIZE) {
      _handleChange({ snackbar: true, snackbarType: 2, snackbarContent: 'The chosen file is too large (5MB maximum)' });
    }
  };

  const [isZoomItem, setIsZoomed] = useState({id: 0, isSelected: false});

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected: handleDropRejected,
    accept: 'image/*',
    multiple: false,
    maxSize: FILE_UPLOAD_SIZE,
  });

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <Paper className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Typography className={classes.sectionTitle}>Photos</Typography>
          </Grid>

          <Grid item xs={12} md={11}>
            <Grid
              container
              spacing={3}
              justify="flex-start"
              alignItems="center"
            >
               <Grid item xs={6} md={4} lg={2}>
                <Paper className={classes.addImageBoxWrapper}>
                  <div {...getRootProps({ className: classes.dropzone })}>
                    <input {...getInputProps()} />
                  </div>

                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={open}
                  >
                    <AddCircleIcon fontSize={'large'}/>
                  </IconButton>
                </Paper>
              </Grid>
              {mainState.files.map((items, index) => {
                let isZoomed = false;
                if (isZoomItem.id == index) {
                  isZoomed = isZoomItem.isSelected;
                }
                return(
                <Grid key={items.preview} item xs={6} md={4} lg={2}>
                  <Paper className={classes.imageBoxWrapper}>
                    <IconButton
                      aria-label="delete"
                      className={classes.closeButton}
                      style={{zIndex: 20}}
                      onClick={handleRemovePhotos(index, items)}
                    >
                      <CancelIcon className={classes.closeIcon} />
                    </IconButton>
                    <div className={classes.image}>
                      <img onClick={() => {setIsZoomed({id: index, isSelected: true})}} className={classes.image}  style={{position: 'absolute', zIndex: 10}} src={items.preview}/>
                      <ControlledZoom isZoomed={isZoomed} wrapStyle={{ width: '100%', position: 'absolute', right: 0, zIndex: 0 }} onZoomChange={() => {setIsZoomed({id: index, isSelected: false})}}>
                          <img className={classes.image} src={items.preview}/>
                      </ControlledZoom>
                    </div>
                    {/*<div className={classes.imageDetails}>*/}
                    {/*  <Typography>{items.name}</Typography>*/}
                    {/*  <MultilineTextField*/}
                    {/*    outlined*/}
                    {/*    fullWidth*/}
                    {/*    stateKey="remark"*/}
                    {/*    value={items.remark}*/}
                    {/*    handleChange={handleChangeDynamic(index)}*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </Paper>
                </Grid>
              )})}

             
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={1}>
            <Typography className={classes.sectionTitle}>Remarks</Typography>
          </Grid>

          <Grid item xs={12} md={11}>
            <MultilineTextField
              outlined
              nonMargin
              fullWidth
              rows={5}
              stateKey="preDiagnoseRemark"
              placeholder={'Enter Remarks'}
              value={mainState.preDiagnoseRemark}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default PreDiagnose;
