import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

// components
import content from "./content.json";
import { utilsContext } from '../../../contexts';

// material ui
import {
  Checkbox, Container,
  Grid, Paper,
  Typography,
} from "@material-ui/core";
import CustomTextField from "../../../components/InputFields/CustomTextField";
import MultilineTextField from "../../../components/InputFields/MultilineTextField";
import CustomSelect from "../../../components/InputFields/CustomSelect";
import CustomTable from "../../../components/CustomTable";

// mock data
const header = [
  { id: 'date', label: 'DATE', disabledSort: true },
  { id: 'repairProgress', label: 'Repair Progress', disabledSort: true },
  { id: 'completeStatus', label: 'Complete Progress', disabledSort: true },
  { id: 'remark', label: 'Remark', disabledSort: true },
  { id: 'updateUser', label: 'Update User', disabledSort: true },
];

const generateBody = (items, classes) => {
  return items.map(e => ({
    date: e.date,
    repairProgress: e.repairProgress,
    completeStatus: e.completeStatus,
    remark: e.remark,
    updateUser: e.updateUser,
  }));
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    marginBottom: 30,
  },
  tableWrapper: {
    marginTop: 30,
  }
}));

function RepairProgress(props) {
  const {mainState, setMainState, permissionArray=[]} = props;
  const classes = useStyles();

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const body = generateBody(mainState.progressItems, classes)

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <Paper className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomTextField
              incline
              outlined
              disabled
              label={content.repairProgress.field_5}
              stateKey="progressUpdateDate"
              value={moment().format('DD-MM-YYYY')}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomSelect
              incline
              label={content.repairProgress.field_1}
              stateKey="repairProgress"
              value={mainState.repairProgress}
              items={mainState.repairProgressArr}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MultilineTextField
              outlined
              singleEl
              label={content.repairProgress.field_2}
              placeholder={'Enter ' + content.repairProgress.field_2}
              stateKey="progressRemarks"
              value={mainState.progressRemarks}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <div style={{marginTop: 8}} />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomSelect
              incline
              label={content.repairProgress.field_3}
              stateKey="completeStatus"
              disabled={!permissionArray.includes('/service-details/update/5')}
              value={mainState.completeStatus}
              items={mainState.completeStatusArr}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MultilineTextField
              outlined
              singleEl
              label={content.repairProgress.field_4}
              placeholder={'Enter ' + content.repairProgress.field_4}
              stateKey="completeStatusRemark"
              value={mainState.completeStatusRemark}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Paper className={classes.tableWrapper}>
          <CustomTable
            headCells={header}
            rows={body}
            page={1}
            pageItems={0}
            rowsPerPage={10}
            order="asc"
            orderBy="createdAT"
            setState={setMainState}
          />
        </Paper>

      </Paper>
    </Container>
  )
}

export default RepairProgress;
