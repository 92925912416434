import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faFolderOpen,
  faFileAlt,
  faFileMedical,
  faMoneyCheckAlt,
  faTags,
  faTag,
  faUsersCog,
  faUsers,
  faUser,
  faUserTag,
  faTimes,
  faList,
} from "@fortawesome/free-solid-svg-icons";

function FontAwesomeMap(props) {

  const switchIcon = (name) => {
    let icon;

    switch (name) {
      case 'fa-cart-line':
        icon = faChartLine;
        break;
      case 'fa-folder-open':
        icon = faFolderOpen;
        break;
      case 'fa-file-alt':
        icon = faFileAlt;
        break;
      case 'fa-file-medical':
        icon = faFileMedical;
        break;
      case 'fa-money-check-alt':
        icon = faMoneyCheckAlt;
        break;
      case 'fa-tags':
        icon = faTags;
        break;
      case 'fa-tag':
        icon = faTag;
        break;
      case 'fa-users-cog':
        icon = faUsersCog;
        break;
      case 'fa-user':
        icon = faUser;
        break;
      case 'fa-users':
        icon = faUsers;
        break;
      case 'fa-user-tag':
        icon = faUserTag;
        break;
      case 'fa-list':
        icon = faList;
        break;
      default:
        icon = faTimes;
    }

    return icon;
  }

  return (
    <FontAwesomeIcon icon={switchIcon(props.icon)}/>
  )
}

export default FontAwesomeMap;
