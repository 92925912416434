import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import clsx from "clsx";
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      width: theme.inputs.width,
      borderRadius: 3,
      backgroundColor: theme.palette.background.selected,
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      padding: '10px 15px',
      fontSize: theme.typography.textFieldLabel.fontSize,
      color: theme.palette.primary.contrastText,
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.border
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      backgroundColor: theme.palette.background.selected,
      borderColor: theme.palette.secondary.main,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'start'
    },
  },
  fullWidth: {
    '& .MuiInputBase-root': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: theme.inputs.width,
    },
  },
  fieldLabel: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    color: theme.palette.primary.contrastText,
    marginRight: 10,
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      flex: '.35',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '.5',
    },
  }
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, suffix, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function NumberInput(props) {
  const {label, value, stateKey, outlined, flexEnd, fullWidth, handleChange} = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.fieldLabel}>{label}</Typography>
      <TextField
        color="secondary"
        align="right"
        className={clsx(classes.root, fullWidth && classes.fullWidth)}
        value={value}
        variant={outlined ? 'outlined' : undefined}
        onChange={handleChange}
        name={stateKey}
        id={stateKey}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
    </div>
  );
}
