import React, {useEffect, useState, useContext, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

// components
import { utilsContext } from '../contexts/index';
import CustomHeader from "./CommonHeader";
import CustomTable from "./../components/CustomTable";
import {getDashboard} from "../api/generals.js";

// material ui
import {
  Container, Paper, Grid,
} from "@material-ui/core";
import CustomDatePickers from "../components/InputFields/CustomDatePickers";
import clsx from "clsx";
import CustomizeButton from "../components/InputFields/CustomizedButtons";

const header = [
  { id: 'name', label: 'Name' },
  { id: 'total', label: 'Total' },
]

const generateBody = (items) => {
  return items.map(e => ({
    name: e.name,
    total: e.total
  }))
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 30,
  },
  createWrapper: {
    marginTop: 30,
  },
  tableWrapper: {
  },
  contentContainer: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    maxWidth: 600,
    margin: 0,
  },
  filterWrapper: {
    margin: '0 0 15px 0',
    backgroundColor: 'transparent',
    position: 'relative',
  },
  actionWrapper: {
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      position: 'initial',
    },
  },
}));

function Dashboard() {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 1,
    rowsPerPage: 10,
    order: 'asc',
    orderBy: 'name',
    startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    items: [],
  })

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleDateChange = key => event => {
    setMainState({ [key]: event });
  }

  const handleSearch = () => {
    getDashboardData();
  }

  // API
  const getDashboardData = async () => {
    _handleChange({ openBackdrop: true })

    let obj = {
      startDate: moment(mainState.startDate).format('YYYY-MM-DD'),
      endDate: moment(mainState.endDate).format('YYYY-MM-DD'),
      orderBy: mainState.orderBy,
      orderType: mainState.order,
    }

    const {success, data} = await getDashboard(obj);
    const failObj = {};
    if (success) {
      setMainState({items: data.details.repairProgressStatus})
    } else {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = 'Fail to get list';
    }

    _handleChange({ openBackdrop: false, ...failObj })
  }

  useEffect(() => {
    async function getInitialized() {
      await getDashboardData();
    }
    getInitialized();
  }, [mainState.orderBy, mainState.order])

  const body = generateBody(mainState.items)

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader title="Dashboard"/>

      <Container  className={classes.contentContainer}>
        <Paper className={classes.filterWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <CustomDatePickers
                label="From"
                incline
                flexEnd
                stateKey="startDate"
                value={mainState.startDate}
                handleChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <CustomDatePickers
                label="To"
                incline
                stateKey="endDate"
                value={mainState.endDate}
                handleChange={handleDateChange}
              />
            </Grid>
          </Grid>

          <div className={classes.actionWrapper}>
            <CustomizeButton label="Search" active handleClick={handleSearch}/>
          </div>
        </Paper>

        <Paper className={classes.tableWrapper}>
          <CustomTable
            noMinWidth
            headCells={header}
            rows={body}
            page={mainState.page}
            pageItems={mainState.pageItems}
            rowsPerPage={mainState.rowsPerPage}
            order={mainState.order}
            orderBy={mainState.orderBy}
            setState={setMainState}
          />
        </Paper>

      </Container>
    </Container>
  )
}

export default Dashboard;
