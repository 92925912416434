import React, {useCallback, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

// components
import content from "./content.json";
import CustomHeader from "../CommonHeader";
import CustomTable from "../../components/CustomTable";
import CustomTextField from '../../components/InputFields/CustomTextField.js';

// material ui
import {
  Grid,
  Paper,
  Container,
  Button,
  Typography,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { getUserList } from '../../api/user';
import CustomizeButton from "../../components/InputFields/CustomizedButtons";

const statusArr = ['Disable', 'Active']

const header = [
  { id: 'id', label: 'ID', disabledSort: true },
  // { id: 'email', label: 'Email', disabledSort: false },
  { id: 'username', label: 'Username', disabledSort: false },
  { id: 'name', label: 'Name', disabledSort: false },
  { id: 'role', label: 'Role', disabledSort: false },
  { id: 'status', label: 'Status', disabledSort: false },
  { id: 'action', label: '', disabledSort: false },
];

const generateBody = (items, page, rowsPerPage, classes, handleRedirect) => {
  return items.map((e, i) => ({
    id: ((page - 1) * rowsPerPage) + i + 1,
    // email: e.email,
    username: e.username,
    displayName: e.displayName,
    roleName: e.roleName,
    status: statusArr[e.status],
    action: <CustomizeButton label="Edit" active handleClick={handleRedirect(e.id)}/>,
  }))
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
  },
  searchWrapper: {
    marginTop: 0,
  },
  tableWrapper: {
    marginTop: 20,
  },
  searchButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
    width: '100%',
  },
  addAccountButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: '#00a53c',
    width: '100%',
  },
  clickText: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    }
  },
}))

function UserList(props) {
  const classes = useStyles();
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 4,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'createdAT',
    search: '',
    items: []
  })

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const handleEditAccountPage = id => () => {
    props.history.push(`/account/${id}`)
  }

  const handleCreateAccountPage = useCallback(() => {
    props.history.push('/account/create')
  }, [])

  const keyPress = useCallback((e) => {
    if(e.keyCode === 13) handleSearch();
  }, []);

  // API functions
  const getStatusList = async () => {
    try {
      // Get UserList List
      let params = {
        limit: mainState.rowsPerPage,
        offset: mainState.page,
        orderType: mainState.order,
        orderBy: mainState.orderBy,
      }

      const userListResult = await getUserList(params);
      if (!userListResult.success) {throw 'Fail to get user list'}
      let userListResultArray = [];
      userListResult.data.details.userList.forEach(e => {
        let obj = {
          id: e.id,
          email: e.email,
          username: e.userName,
          displayName: e.displayName,
          roleName:  e.roleName,
          roleId: e.roleId,
          status: e.status
        }
        userListResultArray.push(obj);
      })
      setMainState({items: userListResultArray, pageItems: userListResult.data.details.totalUser});
    }
    catch(err) {
      console.log(err)
    }
  };

  const handleSearch = async() => {
    try {
      // Get UserList List
      let params = {
        limit: mainState.rowsPerPage,
        offset: mainState.page,
        search: mainState.search
      }
      const userListResult = await getUserList(params);
      if (!userListResult.success) {throw 'Fail to get user list'}
      let userListResultArray = [];
      userListResult.data.details.userList.forEach(e => {
        let obj = {
          id: e.id,
          email: e.email,
          username: e.userName,
          displayName: e.displayName,
          roleName:  e.roleName,
          roleId: e.roleId,
          status: e.status
        }
        userListResultArray.push(obj);
      })
      setMainState({items: userListResultArray, pageItems: userListResult.data.details.totalUser});
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getStatusList();
  }, [mainState.page, mainState.order, mainState.orderBy])

  const body = generateBody(mainState.items, mainState.page, mainState.rowsPerPage, classes, handleEditAccountPage)

  const CreateButton = () => {
    return (
      <CustomizeButton label="Create an Account" active handleClick={handleCreateAccountPage}/>
    )
  }

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader
        title={content.userList.title}
        customComponent={<CreateButton />}
      />

      <Container className={classes.contentContainer}>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          className={classes.searchWrapper}
        >
          <Grid item xs={12} md={3}>
            <CustomTextField
              nonMargin
              outlined
              incline
              label="Search"
              placeholder={content.userList.searchPlaceholder}
              stateKey="search"
              value={mainState.search}
              handleChange={handleChange}
              keyPress={keyPress}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <CustomizeButton label="Search" active handleClick={handleSearch}/>
          </Grid>
        </Grid>

        <Paper className={classes.tableWrapper}>
          <CustomTable
            headCells={header}
            rows={body}
            page={mainState.page}
            pageItems={mainState.pageItems}
            rowsPerPage={mainState.rowsPerPage}
            order={mainState.order}
            orderBy={mainState.orderBy}
            setState={setMainState}
          />
        </Paper>

      </Container>

    </Container>
  )
}

export default UserList;
