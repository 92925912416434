import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import CustomizeButton from "./InputFields/CustomizedButtons";

const useStyles = makeStyles(theme => ({
  container: {
    '& .MuiPaper-root': {
      padding: 20,
    },
  },
  title: {
    '& .MuiTypography-root': {
      fontSize: theme.typography.sectionTitleText.fontSize,
    }
  },
  actionButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    width: '100%',
  },
  cancelButton: {
    background: 'linear-gradient(to right, #7d7d7d, #adadad)',
  },
  deleteButton: {
    background: 'linear-gradient(to right, #ff0000, #ff3131)',
    marginLeft: 10,
  },
}))

export default function AlertDialog(props) {
  const {open, items, handleDelete, setOpen} = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false)
  };

  return (
    <div>
      <Dialog
        open={open}
        className={classes.container}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">{`Are you sure want to delete ${items.name}`}</DialogTitle>
        <DialogActions>
          <CustomizeButton label="Cancel" handleClick={handleClose}/>
          <CustomizeButton label="Delete" active handleClick={handleDelete}/>
          {/*<Button className={clsx(classes.actionButton, classes.cancelButton)} onClick={handleClose} color="primary">*/}
          {/*  Cancel*/}
          {/*</Button>*/}
          {/*<Button className={clsx(classes.actionButton, classes.deleteButton)} onClick={handleDelete} color="primary" autoFocus>*/}
          {/*  Delete*/}
          {/*</Button>*/}
        </DialogActions>
      </Dialog>
    </div>
  );
}
