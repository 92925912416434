import fetchData from '../utils/fetchData';

const group = 'USER';

function userLogin(body) {
  return fetchData({
    url: '/user',
    method: 'POST',
    data: body,
    group,
  });
}

function userLogout() {
  return fetchData({
    url: '/user/logout',
    method: 'POST',
    group,
  });
}

function getUser(params) {
  return fetchData({
    url: '/user',
    method: 'GET',
    params: params,
    group,
  });
}

function createUser(params) {
  return fetchData({
    url: '/user/create',
    method: 'POST',
    data: params,
    group,
  });
}

function getUserList(params) {
  return fetchData({
    url: '/user/user-list',
    method: 'GET',
    params: params,
    group,
  });
}

function updateUserDetails(body) {
  return fetchData({
    url: '/user',
    method: 'PUT',
    data: body,
    group,
  });
}

function updateOtherUserDetails(body) {
  return fetchData({
    url: '/user/update-other-user',
    method: 'PUT',
    data: body,
    group,
  });
}

function updateUserPassword(body) {
  return fetchData({
    url: '/user/update-password',
    method: 'PUT',
    data: body,
    group,
  });
}

function deleteUser(body) {
  return fetchData({
    url: '/user',
    method: 'DELETE',
    data: body,
    group,
  });
}

export {
  userLogin,
  userLogout,
  getUserList,
  updateUserDetails,
  getUser,
  createUser,
  updateUserPassword,
  updateOtherUserDetails,
  deleteUser
}
