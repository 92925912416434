import fetchData from '../utils/fetchData';

const group = 'PERMISSION';

function getRole(params) {
  return fetchData({
    url: '/permission/role',
    method: 'GET',
    params: params,
    group,
  });
}

function updateRole(params) {
  return fetchData({
    url: '/permission/role',
    method: 'PUT',
    data: params,
    group,
  });
}

function getRoleList(params) {
  return fetchData({
    url: '/permission/role-list',
    method: 'GET',
    params: params,
    group,
  });
}

function createRole(params) {
  return fetchData({
    url: '/permission/role',
    method: 'POST',
    data: params,
    group,
  });
}

function deleteRole(params) {
  return fetchData({
    url: '/permission/role',
    method: 'DELETE',
    data: params,
    group,
  });
}


function updatePermissionList(params) {
  return fetchData({
    url: '/permission',
    method: 'PUT',
    data: params,
    group,
  });
}


function getPermissionList() {
  return fetchData({
    url: '/permission',
    method: 'GET',
    group,
  });
}

export {
  getRole,
  updateRole,
  getRoleList,
  createRole,
  deleteRole,
  getPermissionList,
  updatePermissionList
}
