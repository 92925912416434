import React, { Fragment, useState, useMemo } from "react";
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
// import MomentUtils from '@date-io/moment';
import {Typography, TextField, IconButton, OutlinedInput, InputAdornment} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    // justifyContent: 'space-between',
    width: '100%',
    // marginRight: 25,
    marginBottom: 25,

    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& .MuiTypography-root': {
      marginBottom: 8,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  },
  displayIncline: {
    display: 'flex',
    marginBottom: 0,
    '& .MuiFormControl-root': {
      width: theme.inputs.width,
    },
    '& .MuiTypography-root': {
      marginBottom: 0,
    },
  },
  flexEnd: {
    // justifyContent: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: '100%',
  },
  fieldLabel: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    color: theme.palette.primary.contrastText,
    marginRight: 10,
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      flex: '.35',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '.5',
    },
  },
  dateWrapper: {
    '& .react-datepicker__close-icon:after': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .react-datepicker__day--today': {
      backgroundColor: theme.palette.background.icon,
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  inputRoot: {
    '& .MuiInputBase-root': {
      borderRadius: 3,
      backgroundColor: theme.palette.background.selected,
      color: theme.palette.primary.contrastText,
      paddingRight: 0,
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: theme.typography.textFieldLabel.fontSize,
      fontWeight: theme.typography.texFieldValue.fontWeight,
    },
    '& .MuiFormHelperText-contained': {
      position: 'absolute',
      bottom: -13,
      width: 100,
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.border,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      backgroundColor: theme.palette.background.selected,
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiButtonBase-root': {
      color: theme.palette.background.icon,
    },
    '& .MuiInputAdornment-positionEnd': {
      margin: 0,
    }
  },
  disabledBackground: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.disabled,
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      color: theme.palette.background.fixed,
    },
  },
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  nonCurrentMonthDay: {
    color: theme.palette.primary.contrastText,
  },
  todayDate: {
    color: theme.palette.primary.contrastText,
  },
  selectedDate: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const getObjDate = obj => {
  return moment(obj._d).format('YYYY-MM-DD')
}

const getObjDayFormat = obj => {
  return moment(obj._d).format('DD')
}

const generateYear = () => {
  const years = []
  const dateStart = moment('1990-01-01', 'YYYY-MM-DD')
  const dateEnd = moment();
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(dateStart.format('YYYY'))
    dateStart.add(1, 'year')
  }
  return years
}

export default function CustomDatePickers(props) {
  const {label, stateKey, disabled, incline, flexEnd, value, handleChange, clearable, customStyle} = props;
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  // console.log(moment(value)._d, startDate);

  const years = useMemo(() => (generateYear()));
  const months = useMemo(() => ([
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
  ]));

  const renderDay = (date, selectedDate, dayInCurrentMonth) => {
    const dateClone = getObjDate(date);
    const dateDay = getObjDayFormat(date);
    const selectedDateClone = getObjDate(selectedDate);
    const todayDate = moment().format('YYYY-MM-DD');

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.todayDate]: dateClone === todayDate,
      [classes.selectedDate]: dateClone === selectedDateClone
    });

    return (
      <div >
        <IconButton className={dayClassName}>
          <span>{dateDay}</span>
        </IconButton>
      </div>
    );
  }

  const CustomInputButton = ({ value, onClick, onChange }) => {
    return (
      <TextField
        type='text'
        color="secondary"
        variant='outlined'
        className={clsx(classes.inputRoot, disabled && classes.disabledBackground)}
        placeholder="Please Select Date"
        disabled={disabled && !!disabled}
        value={value}
        onChange={onChange}
        onClick={onClick}
      />
    )
  }

  let maxDate = new Date(); 
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  return (
    <div className={clsx(
      classes.container, incline && classes.displayIncline,
      flexEnd && classes.flexEnd
    )}
    >
      <Typography className={classes.fieldLabel}>{label}</Typography>

      <div className={classes.dateWrapper}>
        <DatePicker
          selected={value ? moment(value)._d : null}
          dateFormat="dd-MM-yyyy"
          maxDate={maxDate}
          onChange={handleChange(stateKey)}
          isClearable={clearable && !! clearable}
          disabled={disabled && !!disabled}
          showMonthDropdown
          strictParsing={true}
          customInput={<CustomInputButton/>}
          renderCustomHeader={({
                                 date,
                                 changeYear,
                                 changeMonth,
                                 decreaseMonth,
                                 increaseMonth,
                                 prevMonthButtonDisabled,
                                 nextMonthButtonDisabled
                               }) => (
            <div
              style={{
                margin: 5,
                display: "flex",
                justifyContent: "center"
              }}
            >
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
              </button>
              <select
                style={{fontSize: 10}}
                value={moment(date).format('YYYY')}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                style={{fontSize: 10}}
                value={months[moment(date).format('MM')-1]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
              </button>
            </div>
          )}
        />
      </div>

      {/*<TextField*/}
      {/*  type="date"*/}
      {/*  value={value}*/}
      {/*  disabled={disabled && !!disabled}*/}
      {/*  variant="outlined"*/}
      {/*  onChange={handleChange(stateKey)}*/}
      {/*  className={clsx(classes.inputRoot, disabled && classes.disabledBackground)}*/}
      {/*  InputLabelProps={{*/}
      {/*    shrink: true,*/}
      {/*  }}*/}
      {/*  InputProps={{*/}
      {/*    style: customStyle*/}
      {/*  }}*/}
      {/*/>*/}

      {/*<MuiPickersUtilsProvider utils={MomentUtils}>*/}
      {/*  <KeyboardDatePicker*/}
      {/*    autoOk*/}
      {/*    // disableToolbar*/}
      {/*    clearable*/}
      {/*    orientation="portrait"*/}
      {/*    disabled={disabled && !!disabled}*/}
      {/*    variant="dialog"*/}
      {/*    inputVariant="outlined"*/}
      {/*    format="DD-MM-YYYY"*/}
      {/*    value={value}*/}
      {/*    className={clsx(classes.inputRoot, disabled && classes.disabledBackground)}*/}
      {/*    InputAdornmentProps={{ position: "end" }}*/}
      {/*    onChange={handleChange(stateKey)}*/}
      {/*    renderDay={renderDay}*/}
      {/*  />*/}
      {/*</MuiPickersUtilsProvider>*/}
    </div>
  );
}
