import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: 25,
  },
  root: {
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.textFieldLabel.fontSize,
    '& .MuiOutlinedInput-input': {
      padding: '8px 0 8px 11px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.border
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#8080804a',
    }
  },
  fullWidth: {
    width: '100%',
  },
  incline: {
    display: 'flex',
    alignItems: 'center',
  },
  inclineInput: {
    margin: 0,
    marginLeft: 40,
  },
  fieldLabel: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    color: theme.palette.primary.contrastText
  },
  labelColor: {
    color: theme.palette.secondary.main,
  },
  errorText: {
    color: 'red',
    textAlign: 'end',
  }
}));

export default function CustomPasswordField(props) {
  const classes = useStyles();
  const {label, stateKey, value, labelColor, incline, fullWidth, handleChange, keyPress, error} = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShow = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className={clsx(incline && classes.incline)}>
      <Typography className={clsx(classes.fieldLabel, labelColor && classes.labelColor)}>{label}</Typography>
      <div className={classes.inputWrapper}>
        <OutlinedInput
          id={label}
          type={showPassword ? 'text' : 'password'}
          color="secondary"
          value={value}
          onKeyDown={keyPress && keyPress}
          className={clsx(
            classes.root,
            incline && classes.inclineInput,
            fullWidth && classes.fullWidth,
          )}
          onChange={handleChange(stateKey)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleToggleShow}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && <FormHelperText className={classes.errorText}>{error}</FormHelperText>}
      </div>
    </div>
  );
}
