import React, {useEffect, useCallback, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

// components
import { utilsContext } from '../../contexts';
import content from "./content.json";
import CommonSection from "./CommonSection";
import CustomTextField from '../../components/InputFields/CustomTextField.js';

// material ui
import {Button} from "@material-ui/core";
import { getWarrantyTypes, createWarrantyTypes, updateWarrantyTypes, deleteWarrantyTypes } from '../../api/taxonomic';
import CustomizeButton from "../../components/InputFields/CustomizedButtons";

const header = [
  { id: 'id', label: 'ID', disabledSort: true },
  { id: 'name', label: 'Name' },
  { id: 'action', label: '', disabledSort: true },
]

const generateBody = (items, page, rowsPerPage, classes, handleChange, handleUpdate, setDialog) => {
  return items.map((e, i) => ({
    id: ((page - 1) * rowsPerPage) + i + 1,
    name: <CustomTextField
      outlined
      nonMargin
      stateKey="name"
      value={e.name}
      handleChange={handleChange(i)}
    />,
    action:
      <div className={classes.actionWrapper}>
        <CustomizeButton label="Update" active handleClick={() => handleUpdate(e)}/>
        <CustomizeButton label="Delete" handleClick={setDialog(e)}/>
      </div>
  }))
}


const useStyles = makeStyles(theme => ({
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    width: '100%',
  },
  editButton: {
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
  },
  deleteButton: {
    background: 'linear-gradient(to right, #ff0000, #ff3131)',
    marginLeft: 10,
  },
}))

function WarrantyTypeList() {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 4,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'createdAT',
    name: '',
    items: [],
    deleteItem: {id: '', name: ''},
  })
  const [openDialog, setOpenDialog] = useState(false);

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const handleChangeDynamic = useCallback(index => key => event => {
    const tmpArr = [...mainState.items];
    tmpArr[index][key] = event.target.value;
    setMainState({ items: tmpArr });
  }, [mainState.items]);

  const handleSetDialog = items => () => {
    setMainState({ deleteItem: {id: items.id, name: items.name} })
    setOpenDialog(true);
  }

  useEffect(() => {
    getStatusList();
  }, [mainState.page]);

  // API
  const getStatusList = async () => {

    let obj = {
      limit: mainState.rowsPerPage,
      offset: mainState.page,
    }

    try {
      // Get warranty type List
      const warrantyTypeResult = await getWarrantyTypes(obj);
      if (!warrantyTypeResult.success) {throw 'Fail to get status list'}
      let warrantyTypeResultArray = [];
      warrantyTypeResult.data.details.warrantyType.forEach(e => {
        let obj = {
          id: e.id,
          name: e.name,
        }
        warrantyTypeResultArray.push(obj);
      })
      setMainState({items: warrantyTypeResultArray, pageItems: warrantyTypeResult.data.details.totalWarrantyType});
    }
    catch(err) {
      console.log(err)
    }
  };

  const handleCreate = async() => {
    const {name} = mainState;
    try {
      // Update warranty type Status
      let input = {name: name}

      const createWarrantyTypesResult = await createWarrantyTypes(input);
      if (!createWarrantyTypesResult.success) {throw createWarrantyTypesResult.data.message}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      setMainState({name: ''})
      getStatusList();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
      console.log(err)
    }
  }

  const handleUpdate = async(items) => {
    try {
      // Update Category Status
      let input = {id: items.id, newName: items.name}
      const updateWarrantyTypesResult = await updateWarrantyTypes(input);
      if (!updateWarrantyTypesResult.success) {throw updateWarrantyTypesResult.data.message}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      getStatusList();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
      console.log(err)
    }
  }

  const handleDelete = async () => {
    const {name, deleteItem} = mainState;
    try {
      // Update Category Status
      let input = {id: deleteItem.id}
      const deleteWarrantyTypesResult = await deleteWarrantyTypes(input);
      if (!deleteWarrantyTypesResult.success) {throw 'Fail to delete'}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      getStatusList();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
      console.log(err)
    }
    setOpenDialog(false);
    setMainState({page: 1});
  }

  const body = generateBody(mainState.items, mainState.page, mainState.rowsPerPage, classes, handleChangeDynamic, handleUpdate, handleSetDialog)

  return (
    <CommonSection
      title={content.warrantyType.title}
      mainState={mainState}
      setMainState={setMainState}
      header={header}
      body={body}
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      handleChange={handleChange}
      handleCreate={handleCreate}
      handleDelete={handleDelete}
    />
  )
}

export default WarrantyTypeList;
