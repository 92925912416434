import React from 'react';
import clsx from 'clsx';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import {Typography} from "@material-ui/core";

const BootstrapInput = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.background.border}`,
    borderRadius: 3,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.texFieldValue.fontWeight,
    color: theme.palette.primary.contrastText,
    // backgroundColor: theme.palette.background.paper,
    padding: '4px 40px 4px 11px !important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 3,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    alignItems: 'center',
  },
  displayIncline: {
    display: 'flex',
  },
  selectWrapper: {
    width: theme.inputs.width,
    backgroundColor: theme.palette.background.selected,
    marginTop: 8,
  },
  menuItems: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.texFieldValue.fontWeight,
    color: theme.palette.primary.contrastText,
  },
  fullWidth: {
    width: '100%',
  },
  autoWidth: {
    width: 'auto',
  },
  nonMargin: {
    margin: 0,
  },
  selectLabel: {
    '&.MuiFormLabel-root' : {
      backgroundColor: theme.palette.background.selected,
    },
    '&.MuiFormLabel-root.Mui-focused' : {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.selected,
    }
  },
  fieldLabel: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    color: theme.palette.primary.contrastText,
    marginRight: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: '.35',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '.5',
    },
  },
  flexStart: {
    flex: 0,
  },
  flexEnd: {
    textAlign: 'end',
  }
}));

export default function CustomSelect(props) {
  const { label, stateKey, disabled, incline, value, items, handleChange, autoWidth, fullWidth, flexStart } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={clsx(classes.container, incline && classes.displayIncline)}>
        <Typography className={clsx(
          classes.fieldLabel,
          flexStart && classes.flexStart,
          // incline && classes.flexEnd,
        )}>
          {label}
        </Typography>
        <Select
          className={clsx(
            classes.selectWrapper,
            fullWidth && classes.fullWidth,
            incline && classes.nonMargin,
            autoWidth && classes.autoWidth,
          )}
          id={label}
          color="primary"
          disabled={disabled && !!disabled}
          value={value}
          onChange={handleChange(stateKey)}
          input={<BootstrapInput />}
        >
          <MenuItem value={0} className={classes.menuItems}>
            -- Please Select --
          </MenuItem>
          {
            items.map((item, index) => (
              <MenuItem
                value={item.value}
                key={index + item.label}
                className={classes.menuItems}
              >
                {item.label}
              </MenuItem>
            ))
          }
        </Select>
      </div>
    </React.Fragment>
  );
}
