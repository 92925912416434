import React, {useContext, useEffect, useState, useCallback, memo} from 'react';
import {Redirect} from "react-router-dom"
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// icons
import sonicLogo from "../../assets/pacific-logo.png";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

// components
import DropdownList from './DropdownList.js';
import { userLogout } from "../../api/user.js";
import {utilsContext} from "../../contexts";

import moment from "moment";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 0px 8px #00000024',
    minHeight: 40,
  },
  toolbarItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tooblbarItemText: {
    fontSize: theme.typography.titleText.fontSize,
  },
  logoutButton: {
    cursor: 'pointer',
    marginLeft: 20,
    '&:hover': {
      color: theme.palette.secondary.main,
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: '1px 0px 16px #1c243096',
    backgroundImage: theme.palette.background.navBar,
    padding: '0 10px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight: '32px !important',
  },
  customDivider: {
    marginBottom: 30,
    backgroundColor: 'transparent',
  },
  logoImage: {
    width: 140,
    height: 50,
    position: 'absolute',
    left: 20,
    top: 15,
  },
  toggleButton: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: 24,
  },
  content: {
    flexGrow: 1,
    padding: '24px 16px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    background: theme.palette.background.default,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const MemoNavBar = memo((props) => {
  return <DropdownList {...props} />
})

export default function NavBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { _handleChange } = useContext(utilsContext);
  const [open, setOpen] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [username, setUserName] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState(moment().format('ddd, DD/MM/YYYY HH:mm:ss A'));

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleLogout = useCallback(async () => {
    const {success, descriptions} = await userLogout();

    if (success) {
      localStorage.removeItem("userName");
      localStorage.removeItem("role");
      localStorage.removeItem("userID");
      localStorage.removeItem("permissions");
      setRedirect(true)
    } else {
      _handleChange({ snackbar: true, snackbarType: 1, snackbarContent: descriptions })
    }
  }, []);

  useEffect(() => {
    const name = localStorage.getItem("userName");
    setUserName(name);
    setInterval(() => {
      setCurrentDateTime(moment().format('ddd, DD/MM/YYYY HH:mm:ss A'));
    }, 1000)
  }, [])

  return (
    <div className={classes.root}>
      {redirect && <Redirect to="/login" push/>}
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbarWrapper}>
          <div className={clsx(!open && classes.hide)}/>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.toolbarItemContainer}>
            <Typography variant="h6" noWrap className={classes.tooblbarItemText} style={{marginRight: 20}}>
              {currentDateTime}
            </Typography>

            <Typography variant="h6" noWrap className={classes.tooblbarItemText}>
              Welcome, {username}
            </Typography>
            <PowerSettingsNewIcon className={classes.logoutButton}  onClick={handleLogout} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {/*<Typography className={classes.logoFonts} >Logo Image</Typography>*/}
          <img className={classes.logoImage} src={sonicLogo} alt="sonic-logo"/>
          <IconButton className={classes.toggleButton} onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider className={classes.customDivider} />

        <MemoNavBar open={open} />

      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}
