import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

// components
import CustomHeader from "../CommonHeader";
import CustomTable from "../../components/CustomTable";
import CustomTextField from '../../components/InputFields/CustomTextField.js';
import ActionDialog from "../../components/ActionDialog";
import CustomizeButton from "../../components/InputFields/CustomizedButtons";

// material ui
import {
  Grid,
  Paper,
  Container,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    maxWidth: 'fit-content',
    marginLeft: 0,
  },
  tableWrapper: {
    marginTop: 20,
    width: 500,
  },
  actionButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    width: '100%',
  },
  editButton: {
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
  },
  pageWrapper: {
    paddingBottom: 10,
    paddingRight: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))



export default function CommonSection(props) {
  const {
    title, mainState, setMainState,
    header, body,
    openDialog, setOpenDialog,
    handleChange, handleCreate, handleDelete,
  } = props;
  const classes = useStyles();

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader title={title}/>

      <Container className={classes.contentContainer}>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <CustomTextField
              nonMargin
              outlined
              incline
              label="Name"
              stateKey="name"
              value={mainState.name}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <CustomizeButton label="Create" active handleClick={handleCreate}/>
          </Grid>
        </Grid>

        <Paper className={classes.tableWrapper}>
          <CustomTable
            noMinWidth
            headCells={header}
            rows={body}
            page={mainState.page}
            pageItems={mainState.pageItems}
            rowsPerPage={mainState.rowsPerPage}
            order={mainState.order}
            orderBy={mainState.orderBy}
            setState={setMainState}
          />
        </Paper>

      </Container>

      <ActionDialog
        open={openDialog}
        items={mainState.deleteItem}
        handleDelete={handleDelete}
        setOpen={setOpenDialog}
      />

    </Container>
  )
}
