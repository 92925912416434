import React, {useContext, useCallback, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

// components
import { utilsContext } from '../../contexts';
import content from "./content.json";
import CustomHeader from "../CommonHeader";
import CustomTransferList from "../../components/CustomTransferList";

// material ui
import {
  Paper,
  Container,
  Button, Grid,
} from "@material-ui/core";
import CustomTextField from "../../components/InputFields/CustomTextField";
import { getPermissionList, createRole } from '../../api/permission';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 30,
  },
  formWrapper: {
    marginTop: 20,
    padding: 30,
    boxShadow: '0 5px 20px 0 rgba(72, 72, 72, 0.16)',
    borderRadius: 10,
    '& .MuiPaper-rounded': {
      borderRadius: 10,
    },
  },
  buttonWrapper: {
    textAlign: 'end',
  },
  submitButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
    width: 175,
    marginTop: 50,
  }
}))

function CreateRole(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    name: '',
    currentPermission: [],
    permissionList: [
      // {id: 1, name: 'Assign Role', permissionPath: '/a'},
      // {id: 2, name: 'Assign Permission', permissionPath: '/a'},
      // {id: 3, name: 'Create Service', permissionPath: '/a'},
      // {id: 4, name: 'Edit Service Details', permissionPath: '/a'},
      // {id: 5, name: 'Edit Service Pre-Diagnose', permissionPath: '/a'},
      // {id: 6, name: 'Edit Service Quotation', permissionPath: '/a'},
      // {id: 7, name: 'Edit Service Customer', permissionPath: '/a'},
      // {id: 8, name: 'Edit Service Repair Progress', permissionPath: '/a'},
      // {id: 9, name: 'Edit Service Repair Workflow', permissionPath: '/a'},
      // {id: 10, name: 'Inspect Service', permissionPath: '/a'},
      // {id: 11, name: 'Taxonomy', permissionPath: '/a'},
    ],
  })

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const handleRolePage = useCallback(() => {
    props.history.push('/permission/role')
  }, [])

  // API functions
   const getStatusList = async () => {
    try {
      // Get Permission List
      const permissionListResult = await getPermissionList();
      if (!permissionListResult.success) {throw 'Fail to get status list'}
      let permissionListResultArray = [];
      permissionListResult.data.details.permissionList.forEach(e => {
        let obj = {
          id: e.id,
          name: e.name,
          permissionPath: e.permissionPath,
        }
        permissionListResultArray.push(obj);
      })
      setMainState({permissionList: permissionListResultArray});
    }
    catch(err) {
      console.log(err)
    }
  };

  const handleCreate = async() => {
    const {name, currentPermission} = mainState;
    console.log(currentPermission);
    console.log('handle create');
    try {
      // Create role
      let permissionIdArr = [];
      currentPermission.forEach((i) => {
        permissionIdArr.push(i.id)
      })
      let input = {name: name, permissionId: permissionIdArr}
      const createPermissionResult = await createRole(input);
      if (!createPermissionResult.success) {throw 'Fail to create status'}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      handleRolePage();
    }
    catch(err) {
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: err,
      });
    }
    console.log('handle create');
  }

  useEffect(() => {
    getStatusList();
  }, [])

  return (
    <Container fixed maxWidth="lg">
      <CustomHeader title={content.modifyRole.createTitle} />

      <Paper className={classes.formWrapper}>

        <CustomTextField
          outlined
          fullWidth
          label={content.modifyRole.inputFlied.name}
          stateKey="name"
          value={mainState.name}
          handleChange={handleChange}
        />

        <CustomTransferList
          left={mainState.permissionList}
          right={mainState.currentPermission}
          leftKey="permissionList"
          rightKey="currentPermission"
          leftTitle={content.modifyRole.listRightTitle}
          rightTitle={content.modifyRole.listLeftTitle}
          setState={setMainState}
        />

        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={handleCreate}
          >
            Create
          </Button>
        </div>
      </Paper>
    </Container>
  )
}

export default CreateRole;
