import React, {useEffect, useCallback, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

// components
import { utilsContext } from '../../contexts';
import content from "./content.json";
import CustomHeader from "../CommonHeader";
import CustomTable from "../../components/CustomTable";
import ActionDialog from "../../components/ActionDialog";

// material ui
import {
  Paper,
  Container,
  Button, Grid,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CustomTextField from "../../components/InputFields/CustomTextField";
import { getPermissionList, updatePermissionList } from '../../api/permission';
import CustomizeButton from "../../components/InputFields/CustomizedButtons";

const header = [
  { id: 'id', label: 'ID', disabledSort: true },
  { id: 'name', label: 'Name', disabledSort: true },
  { id: 'action', label: '', disabledSort: true },
];

const generateBody = (items, page, rowsPerPage, classes, handleChange, handleUpdate) => {
  return items.map((e, i) => ({
    id: ((page - 1) * rowsPerPage) + i + 1,
    name: <CustomTextField
      outlined
      nonMargin
      stateKey="name"
      value={e.name}
      handleChange={handleChange(i)}
    />,
    action:
      <div className={classes.actionWrapper}>
        <CustomizeButton label="Update" active handleClick={() => handleUpdate(e)}/>
      </div>
  }))
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    maxWidth: 'fit-content',
    marginLeft: 0,
  },
  tableWrapper: {
    marginTop: 20,
    width: 400,

  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    width: '100%',
  },
  editButton: {
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
  },
}))

function PermissionsList(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 1,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'createdAT',
    items: [
      // {id: 1, name: 'Assign Role', permissionPath: '/a'},
      // {id: 2, name: 'Assign Permission', permissionPath: '/a'},
      // {id: 3, name: 'Create Service', permissionPath: '/a'},
      // {id: 4, name: 'Edit Service Details', permissionPath: '/a'},
      // {id: 5, name: 'Edit Service Pre-Diagnose', permissionPath: '/a'},
      // {id: 6, name: 'Edit Service Quotation', permissionPath: '/a'},
      // {id: 7, name: 'Edit Service Customer', permissionPath: '/a'},
      // {id: 8, name: 'Edit Service Repair Progress', permissionPath: '/a'},
      // {id: 9, name: 'Edit Service Repair Workflow', permissionPath: '/a'},
      // {id: 10, name: 'Inspect Service', permissionPath: '/a'},
      // {id: 11, name: 'Taxonomy', permissionPath: '/a'},
    ],
  })

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChangeDynamic = useCallback(index => key => event => {
    const tmpArr = [...mainState.items];
    tmpArr[index][key] = event.target.value;
    setMainState({ items: tmpArr });
  }, [mainState.items]);

  // API
  const getStatusList = async () => {
    try {
      // Get Permission List
      const permissionListResult = await getPermissionList();
      if (!permissionListResult.success) {throw 'Fail to get status list'}
      let permissionListResultArray = [];
      permissionListResult.data.details.permissionList.forEach(e => {
        let obj = {
          id: e.id,
          name: e.name,
          permissionPath: e.permissionPath,
        }
        permissionListResultArray.push(obj);
      })
      setMainState({items: permissionListResultArray});
    }
    catch(err) {
      console.log(err)
    }
  };

  const handleUpdate = async (items) => {

    try {
      // Update Permission
      let input = {id: items.id, name: items.name}
      const updatePermissionResult = await updatePermissionList(input);
      if (!updatePermissionResult.success) {throw 'Fail to update permission'}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      getStatusList();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
      console.log(err)
    }
  }

  useEffect(() => {
    getStatusList();
  }, [])


  const body = generateBody(mainState.items, mainState.page, mainState.rowsPerPage, classes, handleChangeDynamic, handleUpdate)

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader title={content.permissionList.title} />

      <Container className={classes.contentContainer}>
        <Paper className={classes.tableWrapper}>
          <CustomTable
            noMinWidth
            headCells={header}
            rows={body}
            page={mainState.page}
            pageItems={mainState.pageItems}
            rowsPerPage={mainState.rowsPerPage}
            order={mainState.order}
            orderBy={mainState.orderBy}
            setState={setMainState}
          />
        </Paper>
      </Container>
    </Container>
  )
}

export default PermissionsList;
