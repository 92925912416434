import fetchData from '../utils/fetchData';

const group = 'SERVICE';

function getServiceList(params) {
  return fetchData({
    url: '/service/service-list',
    method: 'GET',
    params: params,
    group,
  });
}

function getTheService(params) {
  return fetchData({
    url: '/service',
    method: 'GET',
    params: params,
    group,
  });
}

function getRelatedService(params) {
  return fetchData({
    url: '/service/related-service-list',
    method: 'GET',
    params: params,
    group,
  });
}

function getRepairRecord(params) {
  return fetchData({
    url: '/service/repair-record-service-list',
    method: 'GET',
    params: params,
    group,
  });
}

function getPreDiagnose(params) {
  return fetchData({
    url: '/service/pre-diagnose',
    method: 'GET',
    params: params,
    group,
  });
}

function getQuotations(params) {
  return fetchData({
    url: '/service/quotation',
    method: 'GET',
    params: params,
    group,
  });
}

function getQuotationsSupplier(params) {
  return fetchData({
    url: '/service/quotation-supplier',
    method: 'GET',
    params: params,
    group,
  });
}

function getCustomerTab(params) {
  return fetchData({
    url: '/service/customer',
    method: 'GET',
    params: params,
    group,
  });
}

function getCustomerRelatedJob(params) {
  return fetchData({
    url: '/service/related-service-list',
    method: 'GET',
    params: params,
    group,
  });
}

function getRepairProgress(params) {
  return fetchData({
    url: '/service/repair-progress',
    method: 'GET',
    params: params,
    group,
  });
}

function getRepairWorkFlow(params) {
  return fetchData({
    url: '/service/repair-workflow',
    method: 'GET',
    params: params,
    group,
  });
}

function getWorkerService(params) {
  return fetchData({
    url: '/service/worker-service',
    method: 'GET',
    params: params,
    group,
  })
}

function updateServices(formData) {
  return fetchData({
    url: '/service',
    method: 'PUT',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    group,
  });
}

function createServices(params) {
  return fetchData({
    url: '/service',
    method: 'POST',
    data: params,
    group,
  });
}

function deleteService(params) {
  return fetchData({
    url: '/service',
    method: 'DELETE',
    data: params,
    group,
  });
}

function updateMultipleService(params) {
  return fetchData({
    url: '/service/multiple-update',
    method: 'POST',
    data: params,
    group,
  });
}

function generateCashSale(params) {
  return fetchData({
    url: '/service/generate-cash-sale',
    method: 'POST',
    data: params,
    group,
  });
}

function getExportService(params) {
  return fetchData({
    url: '/service/service-list-excel',
    method: 'GET',
    params: params,
    group,
  });
}

export {
  getServiceList,
  getTheService,
  getRelatedService,
  getRepairRecord,
  getPreDiagnose,
  getQuotationsSupplier,
  getQuotations,
  getCustomerTab,
  getCustomerRelatedJob,
  getRepairProgress,
  getRepairWorkFlow,
  getWorkerService,
  updateServices,
  createServices,
  deleteService,
  updateMultipleService,
  getExportService,
  generateCashSale
}
