import React from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.background.button,
    border: `1px solid ${theme.palette.background.button}`,
    fontSize: 12,
    textTransform: 'none',
    minWidth: 80,
    '&:hover': {
      backgroundColor: '#288bffde',
      color: theme.palette.primary.main,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  marginZero: {
    marginRight: 5,
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  themeBackground: {
    backgroundColor: theme.palette.background.button,
    color: theme.palette.primary.main,
  },
  buttonProgress: {
    color: theme.palette.background.button,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CustomizedButtons(props) {
  const {label, handleClick, nonSpacing, active, disabled, loading} = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ColorButton
        variant="contained"
        color="primary"
        disabled={(disabled && !!disabled) || loading}
        className={clsx(
          nonSpacing ? classes.marginZero : classes.margin,
          active && classes.themeBackground,
        )}
        onClick={handleClick}
      >
        {label}
      </ColorButton>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}
