import fetchData from '../utils/fetchData';

const group = 'TAXONOMIC';

function getInsuranceCompany() {
  return fetchData({
    url: '/taxonomy/insurance-company',
    method: 'GET',
    group,
  });
}

function createInsuranceCompany(params) {
  return fetchData({
    url: '/taxonomy/insurance-company',
    method: 'POST',
    data: params,
    group,
  });
}

function deleteInsuranceCompany(params) {
  return fetchData({
    url: '/taxonomy/insurance-company',
    method: 'DELETE',
    data: params,
    group,
  });
}

function updateInsuranceCompany(params) {
  return fetchData({
    url: '/taxonomy/insurance-company',
    method: 'PUT',
    data: params,
    group,
  });
}

function getRepairBy(params) {
  return fetchData({
    url: '/taxonomy/repair-by',
    method: 'GET',
    params:params,
    group,
  });
}

function createRepairBy(params) {
  return fetchData({
    url: '/taxonomy/repair-by',
    method: 'POST',
    data: params,
    group,
  });
}

function updateRepairBy(params) {
  return fetchData({
    url: '/taxonomy/repair-by',
    method: 'PUT',
    data: params,
    group,
  });
}

function deleteRepairBy(params) {
  return fetchData({
    url: '/taxonomy/repair-by',
    method: 'DELETE',
    data: params,
    group,
  });
}

function getRepairProgressStatus(params) {
  return fetchData({
    url: '/taxonomy/repair-progress-status',
    method: 'GET',
    params: params,
    group,
  });
}

function createRepairProgressStatus(params) {
  return fetchData({
    url: '/taxonomy/repair-progress-status',
    method: 'POST',
    data: params,
    group,
  });
}

function updateRepairProgressStatus(params) {
  return fetchData({
    url: '/taxonomy/repair-progress-status',
    method: 'PUT',
    data: params,
    group,
  });
}

function deleteRepairProgressStatus(params) {
  return fetchData({
    url: '/taxonomy/repair-progress-status',
    method: 'DELETE',
    data: params,
    group,
  });
}

function getWarrantyTypes(params) {
  return fetchData({
    url: '/taxonomy/warranty-type',
    method: 'GET',
    params: params,
    group,
  });
}

function createWarrantyTypes(params) {
  return fetchData({
    url: '/taxonomy/warranty-type',
    method: 'POST',
    data: params,
    group,
  });
}

function deleteWarrantyTypes(params) {
  return fetchData({
    url: '/taxonomy/warranty-type',
    method: 'DELETE',
    data: params,
    group,
  });
}

function updateWarrantyTypes(params) {
  return fetchData({
    url: '/taxonomy/warranty-type',
    method: 'PUT',
    data: params,
    group,
  });
}
///

function getProductBrand(params) {
  return fetchData({
    url: '/taxonomy/product-brand',
    method: 'GET',
    params: params,
    group,
  });
}

function createProductBrand(params) {
  return fetchData({
    url: '/taxonomy/product-brand',
    method: 'POST',
    data: params,
    group,
  });
}

function deleteProductBrand(params) {
  return fetchData({
    url: '/taxonomy/product-brand',
    method: 'DELETE',
    data: params,
    group,
  });
}

function updateProductBrand(params) {
  return fetchData({
    url: '/taxonomy/product-brand',
    method: 'PUT',
    data: params,
    group,
  });
}

function getProductType(params) {
  return fetchData({
    url: '/taxonomy/product-type',
    method: 'GET',
    params: params,
    group,
  });
}

function createProductType(params) {
  return fetchData({
    url: '/taxonomy/product-type',
    method: 'POST',
    data: params,
    group,
  });
}

function deleteProductType(params) {
  return fetchData({
    url: '/taxonomy/product-type',
    method: 'DELETE',
    data: params,
    group,
  });
}

function updateProductType(params) {
  return fetchData({
    url: '/taxonomy/product-type',
    method: 'PUT',
    data: params,
    group,
  });
}

function getSupplier(params) {
  return fetchData({
    url: '/taxonomy/supplier',
    method: 'GET',
    params: params,
    group,
  });
}

function createSupplier(params) {
  return fetchData({
    url: '/taxonomy/supplier',
    method: 'POST',
    data: params,
    group,
  });
}

function deleteSupplier(params) {
  return fetchData({
    url: '/taxonomy/supplier',
    method: 'DELETE',
    data: params,
    group,
  });
}

function updateSupplier(params) {
  return fetchData({
    url: '/taxonomy/supplier',
    method: 'PUT',
    data: params,
    group,
  });
}
///
function getCompleteStatusList(params) {
  return fetchData({
    url: '/taxonomy/complete-status',
    method: 'GET',
    params: params,
    group,
  });
}

function createCompleteStatus(params) {
  return fetchData({
    url: '/taxonomy/complete-status',
    method: 'POST',
    data: params,
    group,
  });
}

function updateCompleteStatus(params) {
  console.log(params)
  return fetchData({
    url: '/taxonomy/complete-status',
    method: 'PUT',
    data: params,
    group,
  });
}

function deleteCompleteStatus(params) {
  return fetchData({
    url: '/taxonomy/complete-status',
    method: 'DELETE',
    data: params,
    group,
  });
}

function getQuotationCategory() {
  return fetchData({
    url: '/taxonomy/quotation-category',
    method: 'GET',
    group,
  });
}

function createQuotationCategory(params) {
  return fetchData({
    url: '/taxonomy/quotation-category',
    method: 'POST',
    data: params,
    group,
  });
}

function deleteQuotationCategory(params) {
  return fetchData({
    url: '/taxonomy/quotation-category',
    method: 'DELETE',
    data: params,
    group,
  });
}

function updateQuotationCategory(params) {
  return fetchData({
    url: '/taxonomy/quotation-category',
    method: 'PUT',
    data: params,
    group,
  });
}

function getCustomers(params) {
  return fetchData({
    url: '/taxonomy/customer',
    method: 'GET',
    params: params,
    group,
  });
}

function createCustomers(params) {
  return fetchData({
    url: '/taxonomy/customer',
    method: 'POST',
    data: params,
    group,
  });
}

function updateCustomers(params) {
  return fetchData({
    url: '/taxonomy/customer',
    method: 'PUT',
    data: params,
    group,
  });
}

function deleteCustomers(params) {
  return fetchData({
    url: '/taxonomy/customer',
    method: 'DELETE',
    data: params,
    group,
  });
}

function getWorker(params) {
  return fetchData({
    url: '/taxonomy/worker',
    method: 'GET',
    params: params,
    group,
  });
}

function createWorker(params) {
  return fetchData({
    url: '/taxonomy/worker',
    method: 'POST',
    data: params,
    group,
  });
}

function updateWorker(params) {
  return fetchData({
    url: '/taxonomy/worker',
    method: 'PUT',
    data: params,
    group,
  });
}

function deleteWorker(params) {
  return fetchData({
    url: '/taxonomy/worker',
    method: 'DELETE',
    data: params,
    group,
  });
}

function getWorkProcess() {
  return fetchData({
    url: '/taxonomy/workflow-process',
    method: 'GET',
    group,
  })
}

function getWorkProcessWithLimit(params) {
  return fetchData({
    url: '/taxonomy/workflow-process-limit',
    method: 'GET',
    params: params,
    group,
  });
}

function deleteWorkProcess(params) {
  return fetchData({
    url: '/taxonomy/workflow-process',
    method: 'DELETE',
    data: params,
    group,
  });
}

function updateWorkProcess(params) {
  return fetchData({
    url: '/taxonomy/workflow-process',
    method: 'PUT',
    data: params,
    group,
  });
}

function createWorkProcess(params) {
  return fetchData({
    url: '/taxonomy/workflow-process',
    method: 'POST',
    data: params,
    group,
  });
}

export {
  getInsuranceCompany,
  createInsuranceCompany,
  updateInsuranceCompany,
  deleteInsuranceCompany,
  getSupplier,
  createSupplier,
  updateSupplier,
  deleteSupplier,
  getRepairBy,
  createRepairBy,
  updateRepairBy,
  deleteRepairBy,
  getRepairProgressStatus,
  createRepairProgressStatus,
  updateRepairProgressStatus,
  deleteRepairProgressStatus,
  getProductBrand,
  createProductBrand,
  deleteProductBrand,
  updateProductBrand,
  getProductType,
  createProductType,
  deleteProductType,
  updateProductType,
  getWarrantyTypes,
  createWarrantyTypes,
  deleteWarrantyTypes,
  updateWarrantyTypes,
  getCompleteStatusList,
  createCompleteStatus,
  updateCompleteStatus,
  deleteCompleteStatus,
  getQuotationCategory,
  createQuotationCategory,
  updateQuotationCategory,
  deleteQuotationCategory,
  getCustomers,
  createCustomers,
  updateCustomers,
  deleteCustomers,
  getWorker,
  createWorker,
  updateWorker,
  deleteWorker,
  getWorkProcess,
  getWorkProcessWithLimit,
  deleteWorkProcess,
  updateWorkProcess,
  createWorkProcess
}
