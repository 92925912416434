import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

// components
import content from '../tabs/content.json';

// material ui
import {
  Grid,
  Paper,
  Button, Container,
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CustomSelect from "../../../components/InputFields/CustomSelect";
import NumberInput from "../../../components/InputFields/NumberInput";
import MultilineTextField from "../../../components/InputFields/MultilineTextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    padding: 20,
    marginBottom: 40,
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    backgroundColor: theme.palette.background.section,
  },
  sectionTitle: {
    fontSize: theme.typography.sectionTitleText.fontSize,
    color: theme.palette.secondary.main,
  },
  sectionWrapper: {

  },
  sectionHeader: {
    marginBottom: 20,
  },
  sectionSpacer: {
    marginTop: 40,
  },
  addButtonWrapper: {
    // textAlign: 'end',
    marginTop: -20,
    marginBottom: 50,
  },
  addButton: {
    fontSize: 12,
    textTransform: 'none',
  },
  deleteButtonWrapper: {
    textAlign: 'end',
  },
  deleteButton: {
    color: '#e04f1a',
    border: `1px solid #ffede6`,
    backgroundColor: '#ffede6',
    fontSize: 12,
    textTransform: 'none',
  },
  rowContainer: {
    padding: '20px 10px',
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px',
  },
  iconTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 30,

    '& p': {
      width: 'max-content',
      color: theme.palette.primary.contrastText,
    }
  },
  textIcon: {
    color: theme.palette.secondary.main,
    marginRight: 10,
  },
  hideIcon: {
    visibility: 'hidden',
  },
  subRowDivider: {
    borderTop: '1px solid',
    borderColor: theme.palette.background.border,
  },
}));

function RepairWorkflow(props) {
  const {mainState, setMainState} = props;
  const classes = useStyles();

  const handleDynamicChange = index => stateKey => event => {
    const tmpObj = [...mainState.repairWorkFlow];
    tmpObj[index][stateKey] = event.target.value;
    setMainState({ repairWorkFlow: tmpObj });
  }

  const handleChangeAmount = index => event => {
    const {target} = event;
    const tmpObj = [...mainState.repairWorkFlow];
    tmpObj[index][target.name] = target.value;
    setMainState({ repairWorkFlow: tmpObj });
  }

  const handleAddSection = () => {
    const tmpObj = [...mainState.repairWorkFlow];
    const newColumnObj = {
      firstWorkerId: 0,
      secondWorkerId: 0,
      firstProcessId: 0,
      secondProcessId: 0,
      firstRemark: '',
      secondRemark: '',
      firstAmount: 0,
      secondAmount: 0
    }
    tmpObj.push(newColumnObj);
    setMainState({ repairWorkFlow: tmpObj });
  }

  const handleDeleteSection = index => () => {
    const tmpObj = [...mainState.repairWorkFlow];
    tmpObj.splice(index, 1);
    setMainState({ repairWorkFlow: tmpObj });
  }

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      {mainState.repairWorkFlow.map((e, i) => (
        <Paper key={`flow-section-${i}`} className={classes.mainWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={10}>

              <Grid container spacing={1}>
                <Grid item xs={12} lg={4}>
                  <CustomSelect
                    incline
                    label={content.repairWorkFlow.field_1}
                    stateKey="firstProcessId"
                    value={e.firstProcessId}
                    items={mainState.workProcessArr}
                    handleChange={handleDynamicChange(i)}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <CustomSelect
                    incline
                    label={content.repairWorkFlow.field_2}
                    stateKey="firstWorkerId"
                    value={e.firstWorkerId}
                    items={mainState.workerList}
                    handleChange={handleDynamicChange(i)}
                  />
                </Grid>

                <Grid item xs={12} lg={2}>
                  <NumberInput
                    outlined
                    fullWidth
                    label={content.repairWorkFlow.field_3}
                    value={e.firstAmount}
                    stateKey="firstAmount"
                    handleChange={handleChangeAmount(i)}
                  />
                </Grid>
                <Grid item xs={10}>
                  <MultilineTextField
                    alignTop
                    maxWidth
                    outlined
                    fullWidth
                    rows={1}
                    label="Remarks"
                    placeholder={'Enter Remarks'}
                    stateKey="firstRemark"
                    value={e.firstRemark}
                    handleChange={handleDynamicChange(i)}
                  />
                </Grid>
              </Grid>

              {/*<Grid container spacing={1} style={{ marginTop:10 }}>*/}
              {/*  <Grid item xs={12} lg={4}>*/}
              {/*    <CustomSelect*/}
              {/*      incline*/}
              {/*      label={content.repairWorkFlow.field_1}*/}
              {/*      stateKey="secondProcessId"*/}
              {/*      value={e.secondProcessId}*/}
              {/*      items={mainState.workProcessArr}*/}
              {/*      handleChange={handleDynamicChange(i)}*/}
              {/*    />*/}
              {/*  </Grid>*/}

              {/*  <Grid item xs={12} lg={4}>*/}
              {/*    <CustomSelect*/}
              {/*      incline*/}
              {/*      label={content.repairWorkFlow.field_2}*/}
              {/*      stateKey="secondWorkerId"*/}
              {/*      value={e.secondWorkerId}*/}
              {/*      items={mainState.workerList}*/}
              {/*      handleChange={handleDynamicChange(i)}*/}
              {/*    />*/}
              {/*  </Grid>*/}

              {/*  <Grid item xs={12} lg={2}>*/}
              {/*    <NumberInput*/}
              {/*      outlined*/}
              {/*      fullWidth*/}
              {/*      label={content.repairWorkFlow.field_3}*/}
              {/*      value={e.secondAmount}*/}
              {/*      stateKey="secondAmount"*/}
              {/*      handleChange={handleChangeAmount(i)}*/}
              {/*    />*/}
              {/*  </Grid>*/}
              {/*  <Grid item xs={10}>*/}
              {/*    <MultilineTextField*/}
              {/*      maxWidth*/}
              {/*      alignTop*/}
              {/*      outlined*/}
              {/*      fullWidth*/}
              {/*      rows={1}*/}
              {/*      label="Remarks"*/}
              {/*      placeholder={'Enter Remarks'}*/}
              {/*      stateKey="secondRemark"*/}
              {/*      value={e.secondRemark}*/}
              {/*      handleChange={handleDynamicChange(i)}*/}
              {/*    />*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}

            </Grid>
            <Grid item xs={2} className={classes.deleteButtonWrapper}>
              <Button
                color="primary"
                className={classes.deleteButton}
                onClick={handleDeleteSection(i)}
              >
                <DeleteForeverIcon />
                Delete
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ))}

      <div className={classes.addButtonWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.addButton}
          onClick={handleAddSection}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Item
        </Button>
        {/*<Button*/}
        {/*  color="primary"*/}
        {/*  className={classes.addButton}*/}
        {/*  onClick={handleAddSection}*/}
        {/*>*/}
        {/*  ADD NEW SECTION*/}
        {/*  <AddCircleIcon />*/}
        {/*</Button>*/}
      </div>
    </Container>
  )
}

export default RepairWorkflow;
