import { createContext } from 'react'

const defaultState = {
  // generals
  snackbar: false,
  snackbarType: 0,
  snackbarContent: 'Success',
  openBackdrop: false,
};

const utilsContext = createContext(defaultState);
export { defaultState, utilsContext }
