import axios from 'axios';
import variables from '../configs/variables';
// mock data files
import generalsData from '../mockData/generals.json';

const baseURL = variables.apiUrl;

axios.defaults.withCredentials = true;

const fetchData = async (payload, preventRedirect = false) => {
  // Fetch mock data
  if (variables.testDataMode) {
    let dataObj;
    switch (payload.group) {
      case 'GENERALS':
        dataObj = generalsData[payload.url];
        break;
    }

    if (!dataObj) {
      return {
        success: false,
        data: {
          message: 'No Mock Data Found',
        }
      };
    }
    return dataObj;
  }
  // Fetch data from server
  else {
    let requestResult;
    try {
      requestResult = await axios({
        // mode: 'no-cors',
        withCredentials: true,
        baseURL,
        ...payload,
      });
      return { ...requestResult, success: true };
    } catch(err) {
      console.error(err);
      if(err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(err.response);
        if(
          err.response.status === 401
          && payload.url !== '/login'
          && !preventRedirect
        ) {
          // redirect user to login page
          window.localStorage.removeItem('user');
          // const redirectURL = window.location.pathname + window.location.search;
          window.location.href = window.location.origin + '/login?reason=SESSION_EXPIRED';
          return { success: false };
        }
        return { ...err.response, success: false };
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', err.message);
        return { success: false, data: err.message };
      }
    }
  }
};

export default fetchData;
