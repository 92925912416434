import React, {useEffect, useState, useContext, useMemo, memo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router";
import moment from "moment";
import clsx from "clsx";

// components
import variables from '../../../configs/variables';
import content from '../tabs/content.json';
import { utilsContext } from '../../../contexts/index';
import {
  getTheService,
  getPreDiagnose,
  getQuotations,
  getCustomerTab,
  getCustomerRelatedJob,
  getRepairProgress,
  getRepairWorkFlow,
  updateServices, getRepairRecord, deleteService, getQuotationsSupplier, generateCashSale,
} from '../../../api/service';
import {
  getRepairBy,
  getWarrantyTypes,
  getCustomers,
  getWorker,
  getCompleteStatusList,
  getRepairProgressStatus,
  getWorkProcess,
  getProductBrand,
  getProductType,
} from '../../../api/taxonomic.js'
import CustomHeader from "../../CommonHeader";
import ActionDialog from "../../../components/ActionDialog";

// tabs
import ServiceDetails from '../tabs/ServiceDetails';
import PreDiagnose from '../tabs/PreDiagnose';
import Quotation from '../tabs/Quotation';
import RepairProgress from '../tabs/RepairProgress';
import RepairWorkflow from '../tabs/RepairWorkflow';

// material ui
import {
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import CustomizeButton from "../../../components/InputFields/CustomizedButtons";
import {getSupplier} from "../../../api/taxonomic";
import QuotationSupplier from '../tabs/QuotationSupplier';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerContentWrapper: {

  },
  headerButtonWrapper: {

  },
  navWrapper: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  navItemWrapper: {
    position: 'relative',
  },
  navItems: {
    padding: '0 10px',
    color: theme.palette.background.navItem,
    fontSize: theme.typography.textFieldLabel.fontSize,
    cursor: 'pointer',
    transition: 'all .3s',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.textFieldLabel.fontWeight,
    }
  },
  activeNavItem: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
  },
  selectColumn: {
    bottom: '-21px',
    position: 'absolute',
    width: '100%',
    border: `2px solid ${theme.palette.secondary.main}`,
    visibility: 'hidden',
    opacity: 0,
    // transition: 'opacity 2s',
  },
  activeItemBorder: {
    visibility: 'visible',
    opacity: 1,
  },
  pageTitle: {
    fontSize: theme.typography.pageTitleText.fontSize,
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  bodyWrapper: {
    // marginTop: theme.spacing(5),
  },
  tabItemContainer: {
    // marginTop: theme.spacing(5),
    backgroundColor: 'transparent'
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  submitButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
    width: 175,
    marginTop: 50,
    marginLeft: 10
  },
  deleteButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: 'linear-gradient(to right, #ff0000, #ff3131);',
    width: 175,
    marginTop: 50,
    marginRight: 10
  }
}));

function EditServices(props) {
  const classes = useStyles();
  let { serviceID } = useParams();
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions.split(",");
  const tabContent = useMemo(() => content.tabContent.filter((e, i) => permissionArray.includes(`/service-details/${i}`) ), [])
  const { _handleChange } = useContext(utilsContext);
  const [activeStep, setActiveStep] = useState(0);
  const [repairProgressState, setRepairProgressState] = useState(false);


  const [mainState, _setMainState] = useState({
    // Service Details
    jobNumber: '',
    firstOptionNumber: '',
    secondOptionNumber: '',
    thirdOptionNumber: '',
    keyInDate: moment().format('YYYY-MM-DD'),
    jobDate: moment().format('YYYY-MM-DD'),
    purchaseDate: null,
    productType: 0,
    productBrand: 0,
    productTypeArr: [],
    productBrandArr: [],
    modelCode: '',
    serialNumber: '',
    serialNumber2: '',
    supplier: 0,
    supplierItems: [],
    purchasePrice: '',
    recentlyRepair: [],
    repairRecord: [],
    repairBy: 0,
    repairPersonArr: [],
    warrantyType: 0,
    warrantyTypeArr: [],
    refreshRecords: false,
    // Customer
    customerId: 0,
    customerName: '',
    customerPhoneNumber_01: '',
    customerPhoneNumber_02: '',
    customerPhoneNumber_03: '',
    customerAddress: '',
    customerRemarks: '',
    customerFaultyRemark: '',
    customerArr: [],
    relatedJob: [],
    // Feedback
    recentTabLoading: false,
    recordTabLoading: false,
    relatedTabLoading: false,
    // Pre-Diagnose
    files: [],
    createFiles: [],
    deleteFiles: [],
    photoName: '',
    preDiagnoseRemark: '',
    // Quotation
    quotationNo: 'Auto Generate Number',
    cashSaleNo: '',
    customer: '',
    notes: '',
    quotationCat: 0,
    quotationCatRemark: '',
    quotationListRemark: '',
    insuranceCompany: 0,
    insuranceCompanyArr: [],
    quotationItems: [
      {itemName: '', itemCode: '', remark: '', quantity: '', unitPrice: ''},
      {itemName: 'LABOUR', itemCode: '', remark: '', quantity: '', unitPrice: '', label: 'Labour Code'},
      {itemName: 'TRANSPORT', itemCode: '', remark: '', quantity: '', unitPrice: '', label: 'Transport Code'},
    ],
    deposit: 0,
    // Quotation Supplier
    cashSaleNoSupplier: '',
    notesSupplier: '',
    quotationCatRemarkSupplier: '',
    quotationListRemarkSupplier: '',
    quotationItemsSupplier: [
      {itemName: '', itemCode: '', remark: '', quantity: '', unitPrice: ''},
      {itemName: 'LABOUR', itemCode: '', remark: '', quantity: '', unitPrice: '', label: 'Labour Code'},
      {itemName: 'TRANSPORT', itemCode: '', remark: '', quantity: '', unitPrice: '', label: 'Transport Code'},
    ],
    depositSupplier: 0,
    // Repair Progress
    repairProgress: 0,
    repairProgressArr: [],
    progressRemarks: '',
    completeStatus: 0,
    completeStatusArr: [],
    completeStatusRemark: '',
    workerList: [],
    progressItems: [],
    // Repair workflow
    repairWorkFlow: [],
    workProcessArr: [],
  });

  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleSetDialog = items => () => {
    setOpenDialog(true);
  }

  // API
  const getTheServiceDetails = async () => {
    const { success, data } = await getTheService({
      serviceId: serviceID,
    });

    if (success) {
      const e = data.details.service;
      const updateObj = {
        jobNumber: e.number1,
        firstOptionNumber: e.number2 || '',
        secondOptionNumber: e.number3 || '',
        keyInDate: e.keyInDate ? moment(e.keyInDate).format('YYYY-MM-DD') : null,
        jobDate: e.jobDate ? moment(e.jobDate).format('YYYY-MM-DD') : null,
        purchaseDate: e.dayOfPurchase ? moment(e.dayOfPurchase).format('YYYY-MM-DD') : null,
        productType: e.productTypeId || 0,
        productBrand: e.productBrandId || 0,
        modelCode: e.modelCode || '',
        serialNumber: e.serialNumber || '',
        serialNumber2: e.secondSerialNumber || '',
        purchasePrice: e.purchasePrice || '',
        supplier: e.supplierId || 0,
        warrantyType: e.warrantyTypeId || 0,
        repairBy: e.repairById || 0,
        preDiagnoseRemark: e.prediagnoseRemark || '',
      }

      setMainState({ refreshRecords: true, ...updateObj });
      await handleSearchServiceDetails({value: e.serialNumber, value2: e.secondSerialNumber});
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: data.message,
      });
    }
  }

  const handleSearchServiceDetails = async obj => {
    const failObj = {};
    const repairRecordList = [];
    const recentlyRecordList = [];

    setMainState({
      recentTabLoading: true,
      recordTabLoading: true,
    })

    try {
      const relatedListResult = await getRepairRecord({
        serviceId: serviceID,
        serialNumber:  obj.value,
        serialNumber2: obj.value2,
      });
      if (!relatedListResult.success) {throw 'Fail to get repair records'}
      relatedListResult.data.details.map(e => {
        repairRecordList.push({
          id: e.serviceId,
          label: e.jobNumber1,
          date: moment(e.keyInDate).format('DD-MM-YYYY')
        })
      })

      const recentlyListResult = await getRepairRecord({
        serviceId: serviceID,
        serialNumber: obj.value,
        serialNumber2: obj.value2,
        isRepairedIn90Day: true
      });
      if (!recentlyListResult.success) {throw 'Fail to get repair records'}
      recentlyListResult.data.details.map(e => {
        recentlyRecordList.push({
          id: e.serviceId,
          label: e.jobNumber1,
          date: moment(e.keyInDate).format('DD-MM-YYYY')
        })
      })

    } catch (err) {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = err;
    }

    setTimeout(function(){
      setMainState({
        repairRecord: repairRecordList,
        recentlyRepair: recentlyRecordList,
        recentTabLoading: false,
        recordTabLoading: false,
      })
    }, 1000)
  };

  const getTaxonomic = async () => {
    const supplierList = [...mainState.supplierItems];
    const repairTypeList = [...mainState.repairPersonArr];
    const warrantyTypeList = [...mainState.warrantyTypeArr];
    const customerList = [...mainState.customerArr];
    const workerList = [...mainState.workerList];
    const completeStatusList = [...mainState.completeStatusArr];
    const repairProgressList = [...mainState.repairProgressArr];
    const workProcessList = [...mainState.workProcessArr];
    const productTypeList = [...mainState.productTypeArr]
    const productBrandList = [...mainState.productBrandArr]
    try {
      // Get Supplier List
      const supplierResult = await getSupplier();
      if (!supplierResult.success) {throw 'Fail to get supplier list'}
      supplierResult.data.details.supplier.forEach(e => {
        supplierList.push({ label: e.name, value: e.id })
      })

      // Get Company List
      const repairByResult = await getRepairBy();
      if (!repairByResult.success) {throw repairByResult.data.message}
      repairByResult.data.details.repairBy.forEach(e => {
        repairTypeList.push({ label: e.name, value: e.id })
      })

      // Get Warranty Type
      const warrantyTypeResult = await getWarrantyTypes();
      if (!warrantyTypeResult.success) {throw warrantyTypeResult.data.message}
      warrantyTypeResult.data.details.warrantyType.forEach(e => {
        warrantyTypeList.push({ label: e.name, value: e.id })
      })

      // Get Customers
      const customerResult = await getCustomers();
      if (!customerResult.success) {throw customerResult.data.message}
      customerResult.data.details.customer.forEach(e => {
        customerList.push({ label: e.name, value: e.id })
      })

      // Get Workers
      const workerResult = await getWorker();
      if (!workerResult.success) {throw workerResult.data.message}
      workerResult.data.details.worker.forEach(e => {
        workerList.push({ label: e.name, value: e.id })
      })

      // Get Complete Status
      const completeStatusResult = await getCompleteStatusList();
      if (!completeStatusResult.success) {throw completeStatusResult.data.message}
      completeStatusResult.data.details.completeStatus.forEach(e => {
        completeStatusList.push({ label: e.name, value: e.id })
      })

      // Get Repair Progress List
      const repairProgressResult = await getRepairProgressStatus();
      if (!repairProgressResult.success) {throw repairProgressResult.data.message}
      repairProgressResult.data.details.repairProgressStatus.forEach(e => {
        repairProgressList.push({ label: e.name, value: e.id })
      })

      // Get Repair Progress List
      const workProcessResult = await getWorkProcess();
      if (!workProcessResult.success) {throw workProcessResult.data.message}
      workProcessResult.data.details.forEach(e => {
        workProcessList.push({ label: e.name, value: e.id })
      })

      // Get Product Brand list
      const productBrandResult = await getProductBrand();
      if (!productBrandResult.success) {throw productBrandResult.data.message}
        productBrandResult.data.details.productBrand.forEach(e => {
          productBrandList.push({ label: e.name, value: e.id })
        })

       // Get Product Type list
       const productTypeResult = await getProductType();
       if (!productTypeResult.success) {throw productTypeResult.data.message}
       productTypeResult.data.details.productType.forEach(e => {
         productTypeList.push({ label: e.name, value: e.id })
       })
    } catch (err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
    }

    setMainState({
      supplierItems: supplierList,
      repairPersonArr: repairTypeList,
      warrantyTypeArr: warrantyTypeList,
      customerArr: customerList,
      workerList: workerList,
      completeStatusArr: completeStatusList,
      repairProgressArr: repairProgressList,
      workProcessArr: workProcessList,
      productBrandArr: productBrandList,
      productTypeArr: productTypeList
    })
  }

  const getPreDiagnoseList = async () => {
    const { success, data } = await getPreDiagnose({
      serviceId: serviceID,
    });

    if (success) {
      const tmpAr = data.details.preDiagnoses.map(e => ({
        id: e.id,
        name: e.name || '',
        preview: variables.imageUrl + e.imagePath,
        remark: e.remark || '',
      }))
      setMainState({ files: tmpAr });
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: data.message,
      });
    }
  }

  const getQuotationSupplierList = async () => {
    const { quotationItemsSupplier } = mainState;
    const { success, data } = await getQuotationsSupplier({
      serviceId: serviceID,
    });

    if (success) {
      const e = data.details.quotation;
      let newQuotationItems = quotationItemsSupplier;

      if (e.quotationItemListJson) {
        newQuotationItems = e.quotationItemListJson.map(e => {
          let label;
          if (e.itemName === 'LABOUR') label = 'Labour Code';
          if (e.itemName === 'TRANSPORT') label = 'Transport Code';

          return {
            itemCode: e.itemCode,
            itemName: e.itemName,
            quantity: e.quantity,
            remark: e.remark,
            totalPrice: e.totalPrice,
            unitPrice: e.unitPrice,
            label,
          }
        })
      }
      const updateObj = {
        cashSaleNoSupplier: e.cashSaleNo,
        quotationCatRemarkSupplier: e.quotationRemark ? e.quotationRemark : '',
        quotationListRemarkSupplier: e.quotationListRemark ? e.quotationListRemark : '',
        notesSupplier: e.remark ? e.remark : '',
        depositSupplier: e.deposit,
        quotationItemsSupplier: newQuotationItems,
      }

      setMainState(updateObj);
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: data.message,
      });
    }
  }

  const getQuotationList = async () => {
    const { quotationItems } = mainState;
    const { success, data } = await getQuotations({
      serviceId: serviceID,
    });
    if (success) {
      const e = data.details.quotation;
      let newQuotationItems = quotationItems;

      if (e.quotationItemListJson) {
        newQuotationItems = e.quotationItemListJson.map(e => {
          let label;
          if (e.itemName === 'LABOUR') label = 'Labour Code';
          if (e.itemName === 'TRANSPORT') label = 'Transport Code';

          return {
            itemCode: e.itemCode,
            itemName: e.itemName,
            quantity: e.quantity,
            remark: e.remark,
            totalPrice: e.totalPrice,
            unitPrice: e.unitPrice,
            label,
          }
        })
      }
      const updateObj = {
        quotationNo: e.id,
        cashSaleNo: e.cashSaleNo,
        customer: e.customerName,
        insuranceCompany: e.insuranceCompany ? e.insuranceCompany.id : 0,
        quotationCat: e.quotationCategory ? e.quotationCategory.id : 0,
        quotationCatRemark: e.quotationRemark,
        quotationListRemark: e.quotationListRemark,
        deposit: e.deposit,
        notes: e.remark,
        quotationItems: newQuotationItems,
      }

      setMainState(updateObj);
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: data.message,
      });
    }
  }

  const getCustomerDetails = async () => {
    const { success, data } = await getCustomerTab({
      serviceId: serviceID,
    });

    if (success) {
      const e = data.details.customer;
      const updateObj = {
        customerId: e.customerId || 0,
        customerName: e.customerName || '',
        customerPhoneNumber_01: e.phoneNumber1 || '',
        customerPhoneNumber_02: e.phoneNumber2 || '',
        customerPhoneNumber_03: e.phoneNumber3 || '',
        customerAddress: e.customerAddress || '',
        customerRemarks: e.remark || '',
        customerFaultyRemark: e.faultyDescription || '',
      }

      await handleSearchCustomer({
        number: e.phoneNumber1,
        number2: e.phoneNumber2,
        number3: e.phoneNumber3,
      })

      setMainState({ ...updateObj });
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: data.message,
      });
    }
  }

  const handleSearchCustomer = async obj => {
    setMainState({ relatedTabLoading: true });

    const customerRelatedResult = await getCustomerRelatedJob({
      serviceId: serviceID,
      phoneNumber1: obj.number,
      phoneNumber2: obj.number2,
      phoneNumber3: obj.number3,
    });

    if (customerRelatedResult.success) {
      const tmpArr = customerRelatedResult.data.details.map(e => ({
        id: e.serviceId,
        date: moment(e.keyInDate).format('YYYY/MM/DD'),
        label: e.serialNumber,
      }))

      setTimeout(function() {
        setMainState({
          relatedJob: tmpArr,
          relatedTabLoading: false,
        });
      },1000)
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: 'Fail to get related list',
      });
      setMainState({relatedTabLoading: false});
    }
  }

  const handleRedirect = () => {
    props.history.push(`/service`)
  }

  const getRepairProgressDetails = async() => {
    const {success, data} = await getRepairProgress({
      serviceId: serviceID,
    });

    if (success) {
      const tmpData = data.details.repairProgress.map(e => ({
        date: moment(e.created_at).format('YYYY-MM-DD HH:mm'),
        repairProgress: e.repairProgressStatusName,
        completeStatus: e.completeStatusName,
        remark: e.completeStatusRemark ? e.completeStatusRemark : e.progressRemark,
        updateUser: e.userName
      }))
      setMainState({progressItems: tmpData})
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: data.message,
      });
    }
  }

  const getRepairWorkFlowList = async () => {
    const {success, data} = await getRepairWorkFlow({
      serviceId: serviceID,
    });

    if (success) {
      const obj = data.details.repairWorkflow.map(e => ({
        firstWorkerId: e.firstWorkerId || 0,
        secondWorkerId: e.secondWorkerId || 0,
        firstProcessId: e.firstProcessId || 0,
        secondProcessId: e.secondProcessId || 0,
        firstRemark: e.firstRemark || '',
        secondRemark: e.secondRemark || '',
        firstAmount: e.firstAmount,
        secondAmount: e.secondAmount
      }))
      setMainState({repairWorkFlow: obj})
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: data.message,
      });
    }
  }

  const handleDelete = async () => {
    try {
      // Update Insurance Company Status
      let input = {serviceId: [serviceID]}
      const deleteServiceResult = await deleteService(input);
      if (!deleteServiceResult.success) {throw 'Fail to delete service'}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });

      handleRedirect();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
    }
  }

  const handleBack = () => {
    window.history.back();
  }

  const handleGenerateCashSale = async (isSupplier) => {
    try {
      let input = {serviceId: serviceID, isSupplier: isSupplier}
      const generateResult = await generateCashSale(input);
      if (!generateResult.success) {throw 'Fail to generate service'}
      if (generateResult.data.details.cashSaleNo) {
        if (isSupplier) {
          setMainState({cashSaleNoSupplier: generateResult.data.details.cashSaleNo});
        }
        else {
          setMainState({cashSaleNo: generateResult.data.details.cashSaleNo});
        }
      }
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
    }
  }

  const handleSubmit = async () => {
    const { jobNumber, firstOptionNumber, secondOptionNumber, repairBy,
      warrantyType, keyInDate, jobDate, productBrand, productType,
      serialNumber, serialNumber2, modelCode, purchaseDate, purchasePrice,
      supplier, preDiagnoseRemark,
    } = mainState;
    const {files, createFiles, deleteFiles} = mainState;
    const updateFiles = files.filter(file => file.id);
    const createFileFormat = createFiles.map(e => ({...e, originalFileName: e.name}))
    const {quotationCat, insuranceCompany, customer, notes, quotationCatRemark, quotationItems, deposit, quotationListRemark} = mainState;
    const {notesSupplier, quotationCatRemarkSupplier, quotationItemsSupplier, depositSupplier, quotationListRemarkSupplier} = mainState;
    const {customerId, customerName, customerPhoneNumber_01, customerPhoneNumber_02,
      customerPhoneNumber_03, customerAddress, customerRemarks, customerFaultyRemark
    } = mainState;
    const {repairProgress, completeStatus, progressRemarks, completeStatusRemark} = mainState;

    const filterWorkflow = mainState.repairWorkFlow.map(e => ({
      firstWorkerId: e.firstWorkerId === 0 ?  null : e.firstWorkerId,
      secondWorkerId: e.secondWorkerId === 0 ? null : e.secondWorkerId,
      firstProcessId: e.firstProcessId === 0 ? null : e.firstProcessId,
      secondProcessId: e.secondProcessId === 0 ? null : e.secondProcessId,
      firstRemark: e.firstRemark,
      secondRemark: e.secondRemark,
      firstAmount: e.firstAmount,
      secondAmount: e.secondAmount
    }))

    const updateObj = {
      serviceId: serviceID,
      service: {
        number1: jobNumber,
        number2: firstOptionNumber,
        number3: secondOptionNumber,
        keyInDate: keyInDate ? moment(keyInDate).format('YYYY-MM-DD') : null,
        jobDate: jobDate ? moment(jobDate).format('YYYY-MM-DD') : null,
        supplierId: supplier || null,
        repairById: repairBy || null,
        productBrandId: productBrand || null,
        productTypeId: productType || null,
        modelCode: modelCode,
        dayOfPurchase: purchaseDate ? moment(purchaseDate).format('YYYY-MM-DD') : null,
        warrantyTypeId: warrantyType || null,
        purchasePrice: purchasePrice === '' ? null : purchasePrice,
        serialNumber: serialNumber,
        serialNumber2: serialNumber2,
        prediagnoseRemark: preDiagnoseRemark,
      },
      preDiagnose: {
        create: createFileFormat,
        update: updateFiles,
        delete: deleteFiles,
      },
      quotation: {
        quotationCategoryId: quotationCat,
        insuranceCompanyId: insuranceCompany,
        customerName: customer,
        remark: notes,
        quotationCategoryRemark: quotationCatRemark,
        quotationListRemark: quotationListRemark,
        quotationItemListJson: quotationItems,
        deposit: deposit,
      },
      quotationSupplier: {
        deposit: depositSupplier,
        remark: notesSupplier,
        quotationCategoryRemark: quotationCatRemarkSupplier,
        quotationListRemark: quotationListRemarkSupplier,
        quotationItemListJson: quotationItemsSupplier,
      },
      customer: {
        customerId: customerId || null,
        customerName: customerName,
        phoneNumber: customerPhoneNumber_01,
        phoneNumber2: customerPhoneNumber_02,
        phoneNumber3: customerPhoneNumber_03,
        customerAddress: customerAddress,
        faultyDescription: customerFaultyRemark,
        remark: customerRemarks,
      },
      repairProgress: {
        repairProgressStatusId: repairProgress,
        completeStatusId: completeStatus,
        date: moment().format('YYYY-MM-DD'),
        progressRemark: progressRemarks,
        completeStatusRemark: completeStatusRemark
      },
      repairWorkflow: filterWorkflow,
    }

    const formData = new FormData();
    formData.append('updateServiceObj', JSON.stringify(updateObj));
    createFiles.forEach(file => {
      formData.append('photos', file)
    })

    const {success, data} = await updateServices(formData);

    const snackBarObj = {};
    if (success) {
      snackBarObj.snackbarType = 0;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = 'Update Successful'
      setRepairProgressState(!repairProgressState);
      await getPreDiagnoseList();
      setMainState({ repairProgress: 0, completeStatus: 0, progressRemarks: '', completeStatusRemark: '', createFiles:[] })
    } else {
      snackBarObj.snackbarType = 1;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = data.message;
    }

    _handleChange(snackBarObj)
  }

  useEffect(() => {
    async function getServicesTriggers() {
      _handleChange({ openBackdrop: true });
      await getTaxonomic();
      await getTheServiceDetails();
      await getCustomerDetails();
      await getPreDiagnoseList();
      await getQuotationList();
      await getQuotationSupplierList();
      await getRepairWorkFlowList();
      _handleChange({ openBackdrop: false });
    }
    getServicesTriggers();
  }, [])

  useEffect(() => {
    async function updateProgressTriggers() {
      _handleChange({ openBackdrop: true });
      await getRepairProgressDetails();
      _handleChange({ openBackdrop: false });
    }
    updateProgressTriggers();
  }, [repairProgressState])

  const getStepUrl = useMemo(() => `/service-details/update/${activeStep}`, [activeStep])

  const HeaderNav = () => {
    return (
      <div className={classes.navWrapper}>
        {tabContent.map((e, i) => (
          <div key={`nav-head-${i}`} className={classes.navItemWrapper}>
            <Typography
              className={clsx(
                classes.navItems,
                (activeStep === content.tabContent.indexOf(e)) && classes.activeNavItem
              )}
              onClick={() => {
                setActiveStep(content.tabContent.indexOf(e))}
              }
            >
              {e}
            </Typography>
            <span className={clsx(classes.selectColumn, (activeStep === content.tabContent.indexOf(e)) && classes.activeItemBorder)} />
          </div>
        ))}
      </div>
    )
  };
  return (
    <Container fixed style={{ padding: 0 }}>
      <CustomHeader
        pdf
        serviceID={serviceID}
        title={content.title}
        subTitle={content.subTitle}
        customHead={<HeaderNav />}
        details={mainState}
      />

      <div className={classes.bodyWrapper}>
        <Paper className={classes.tabItemContainer}>
          {activeStep === 0 &&
            <ServiceDetails
              serviceID={serviceID}
              mainState={mainState}
              setMainState={setMainState}
              handleSearch={handleSearchServiceDetails}
              handleCustomerSearch={handleSearchCustomer}
            />
          }
          {activeStep === 1 &&
            <PreDiagnose
              serviceID={serviceID}
              mainState={mainState}
              setMainState={setMainState}
            />
          }
          {activeStep === 2 &&
            <QuotationSupplier
            serviceID={serviceID}
            mainState={mainState}
            setMainState={setMainState}
            handleGenerateCashSale={handleGenerateCashSale}
          />
          }
          {activeStep === 3 &&
             <Quotation
             serviceID={serviceID}
             mainState={mainState}
             setMainState={setMainState}
             handleGenerateCashSale={handleGenerateCashSale}
           />
          }
          {activeStep === 4 &&
            <RepairProgress
              serviceID={serviceID}
              permissionArray={permissionArray}
              mainState={mainState}
              setMainState={setMainState}
            />
          }
          {activeStep === 5 &&
            <RepairWorkflow
              serviceID={serviceID}
              mainState={mainState}
              setMainState={setMainState}
            />
          }
        </Paper>

        <div className={classes.buttonWrapper}>
          <CustomizeButton
            active
            disabled={!permissionArray.includes(getStepUrl)}
            label="Update Service"
            handleClick={handleSubmit}
          />
          <CustomizeButton label="Cancel" handleClick={handleBack}/>
        </div>

        <ActionDialog
          open={openDialog}
          items={{name: "service"}}
          handleDelete={handleDelete}
          setOpen={setOpenDialog}
        />
      </div>
    </Container>
  )
}

export default EditServices;
