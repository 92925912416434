import React, { useState } from 'react';
import moment from "moment";
import {PDFViewer, Page, Text, View, Image, Document, StyleSheet, Font} from '@react-pdf/renderer';
import variables from "../configs/variables.json";
import font from "./chinese.msyh.ttf";

Font.register({ family: 'Microsoft', src: font });

// Create styles
const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '90vh'
  },
  page: {
    padding: '20px 20px 0',
  },
  leftImage: {
    width: 35,
    height: 40,
    marginRight: 10,
  },
  rightImage: {
    width: 135,
    height: 65,
  },
  leftHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageHeader: {
    border: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0 10px 10px',
  },
  headerContentWrapper: {
    // alignItems: 'center',
    // marginLeft: 10,
  },
  inclineText: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  tableWrapper: {
    // backgroundColor: '#e2e2e2',
    padding: 0,
    flexDirection: 'row',
    borderBottom: 1,
    borderLeft: 1,
    borderRight: 1,
  },
  col: {
    width: 500,
    flexDirection: 'row',
    padding: '0px 3px',
    borderLeft: 0,
  },
  col2: {
    width: 346,
    flexDirection: 'row',
    padding: '0px 3px',
    borderRight: 1,
    borderLeft: 0,
  },
  col3: {
    width: 173,
    padding: '0px 3px',
    flexDirection: 'row',
    borderRight: 1,
  },
  colEnd: {
    width: '290px',
    padding: '0px 3px',
    flexDirection: 'row',
    borderRight: 0,
  },
  innerColumnLabel: {
    width: 65,
    padding: '5px 0px',
  },
  specialInnerColumnLabel: {
    width: 25,
    padding: '5px 0px',
  },
  innerColumnValue: {
    borderLeft: 1,
    padding: 5,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 900,
  },
  secondTitleText: {
    fontSize: 14,
    fontWeight: 900,
    marginLeft: 20,
  },
  contentText: {
    fontSize: 10,
  },
  contentTimeText: {
    fontSize: 10,
    textAlign: 'center',
  },
  chineseText: {
    fontSize: 10,
    fontFamily: 'Microsoft',
  },
  alignContentText: {
    fontSize: 10,
    textAlign: 'left',
  },
  tableContainer: {
    borderLeft: 0,
  },
  listTableWrapper: {
    flexDirection: 'row',
  },
  tableListDivider: {
    marginTop: 30,
  },
  tableTr10: {
    width: 30,
    backgroundColor: '#e4e4e4',
    padding: '5px 2px',
    textAlign: 'center',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
  },
  tableTd10: {
    width: 30,
    padding: '5px 2px',
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
  },
  tableTr20: {
    width: 80,
    backgroundColor: '#e4e4e4',
    padding: '5px 2px',
    textAlign: 'center',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
  },
  tableTd20: {
    width: 80,
    padding: '5px 2px',
    textAlign: 'center',
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
  },
  tableTr40: {
    width: 100,
    backgroundColor: '#e4e4e4',
    padding: '5px 2px',
    textAlign: 'center',
    borderTop: 1,
    borderRight: 1,
    borderBottom: 1,
  },
  tableTd40: {
    width: 100,
    padding: '5px 2px',
    textAlign: 'right',
    borderRight: 1,
    borderBottom: 1,
  },
  tableTr60: {
    width: 345,
    backgroundColor: '#e4e4e4',
    padding: '5px 2px',
    textAlign: 'center',
    borderTop: 1,
    borderBottom: 1,
  },
  tableTd60: {
    width: 345,
    padding: '5px 2px',
    textAlign: 'left',
    borderBottom: 1,
  },
  tableText: {
    fontSize: 10,
  },
  tinyText: {
    fontSize: 6,
    marginBottom: 2,
    marginLeft: 2,
  },
  signWrapper: {
    marginTop: 80,
    flexDirection: 'row',
  },
  technicianWrapper: {
    width: 230,
  },
  dateWrapper: {
    width: 180,
    marginLeft: 20,
  },
  technicianSignArea: {
    borderBottom: 1,
  },
  dateInput: {
    borderBottom: 1,
  },
  ownerLabel: {
    width: 130,
    padding: '3px 0',
    textAlign: 'right',
  },
  clientWrapper: {
    marginLeft: 40,
  },
  clientSign: {
    borderBottom: 1,
    width: 230,
  },
  clientLabel: {
    padding: '3px 0',
  },
  memoWrapper: {
    marginTop: 20,
  },
  signContainer: {
    position: 'absolute',
    bottom: 30,
    left: 20,
    right: 0,
  }
});

function QuotationPdf(props) {
  const { details, pdf } = props;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  let supplierValue, repairerValue, warrantyValue, brandValue, productTypeValue;

  details.supplierItems.forEach(e => {
    if (e.value === details.supplier) supplierValue = e.label;
  })

  details.repairPersonArr.forEach(e => {
    if (e.value === details.repairBy) repairerValue = e.label;
  })

  details.warrantyTypeArr.forEach(e => {
    if (e.value === details.warrantyType) warrantyValue = e.label;
  })

  details.productBrandArr.forEach(e => {
    if (e.value === details.productBrand) brandValue = e.label;
  })

  details.productTypeArr.forEach(e => {
    if (e.value === details.productType) productTypeValue = e.label;
  })

  const tdItem = ['No.', 'Item', 'Quantity', 'Total Price(RM)']
  let totalQuotationPrice = 0;
  let newTableItem = [];
  details.quotationItems.forEach((e, i) => {
    const tmpTotal = parseFloat(e.quantity) * parseFloat(e.unitPrice);
    const actualTotal = tmpTotal || 0;
    totalQuotationPrice += actualTotal;
    if (e.itemName
      || e.quantity
      || e.actualTotal
    ) {
      newTableItem.push({
        no: i + 1,
        item: e.itemName,
        quantity: e.quantity,
        totalPrice: actualTotal
      })
    }
  })

  newTableItem.push({
    no: '',
    item: '',
    quantity: 'Total',
    totalPrice: totalQuotationPrice
  })

  newTableItem.push({
    no: 'NOTES',
    item: '$LONG$',
    quantity: 'Deposit',
    totalPrice: `-${details.deposit || 0}`
  })

  newTableItem.push({
    no: 'REMARK',
    item: '$LONG$' + details.quotationListRemark,
    quantity: 'Amount',
    totalPrice: (totalQuotationPrice - details.deposit) || 0
  })

  newTableItem.forEach((e, i) => {
    if (i < newTableItem.length - 3) {
      newTableItem[i].no = i+1;
    }
  })

  const getTableColumnTrStyle = index => {
    let returnStyle;

    if (index === 0) {
      returnStyle = styles.tableTr10;
    } else if (index === 1) {
      returnStyle = styles.tableTr60;
    } else if (index === 2) {
      returnStyle = styles.tableTr20;
    } else if (index === 3) {
      returnStyle = styles.tableTr40;
    }

    return returnStyle;
  }

  const getTableColumnTdStyle = (index, noBorder) => {
    let returnStyle;

    if (index === 0) {
      returnStyle = styles.tableTd10;
      if (noBorder)  returnStyle = {...styles.tableTd10, borderLeft: 3, borderColor: 'white'}
    } else if (index === 1) {
      returnStyle = styles.tableTd60;
      if (noBorder)  returnStyle = {...styles.tableTd60, borderColor: 'white'}
    } else if (index === 2) {
      returnStyle = styles.tableTd20;
    } else if (index === 3) {
      returnStyle = styles.tableTd40;
    }

    return returnStyle;
  }

  return (
    // <PDFViewer style={styles.container}>
    <Document
      file={pdf}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.imageHeader}>
          <View style={styles.leftHeaderWrapper}>
            <Image
              style={styles.leftImage}
              src={`${variables.siteUrl}/pacific-logo.png`}
            />

            <View style={styles.headerContentWrapper}>
              <View style={styles.inclineText}>
                <Text style={styles.titleText}>PACIFIC ELECTRIC SERVICES</Text>
                <Text style={styles.tinyText}>(NS0197563-W)</Text>
              </View>
              <Text style={styles.contentText}>42, JLN S2 D37, City Centre, Seremban 2, 70300 Seremban, Negeri Sembilan.</Text>
              <View style={styles.inclineText}>
                <Text style={styles.titleText}>H/P: 012-676 5577 / 012-676 5958</Text>
                <Text style={styles.secondTitleText}>Tel: 06-603 1688</Text>
              </View>
              <Text style={styles.contentText}>pacificservies@outlook.my</Text>
            </View>
          </View>

          <Image
            style={styles.rightImage}
            src={`${variables.siteUrl}/combine-img.png`}
          />
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>SUPPLIER</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{supplierValue}</Text>
            </View>
          </View>

          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>PHOTO</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> </Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>DATE</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.jobDate ? moment(details.jobDate).format('DD-MM-YYYY') : ''}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>JOB NUM 2</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{details.firstOptionNumber}</Text>
            </View>
          </View>

          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>JOB NUM 3</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{details.secondOptionNumber}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>JOB NUM 1</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{details.jobNumber}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>NAME</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerName}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>CASH SALE</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{details.cashSaleNo}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>ADDRESS</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerAddress.replace(/(\r\n|\n|\r)/gm, "")}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>TEL NUM 1</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerPhoneNumber_01}</Text>
            </View>
          </View>

          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>BRAND</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {brandValue}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>MODEL</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.modelCode}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>TEL NUM 2</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerPhoneNumber_02}</Text>
            </View>
          </View>

          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>PRODUCT</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {productTypeValue}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>S/N NUM</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.serialNumber}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>TEL NUM 3</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerPhoneNumber_03}</Text>
            </View>
          </View>

          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>W/TYPE</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {warrantyValue}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>NOTES</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.notes}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>REMARK</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{details.quotationCatRemark}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableListDivider} />

        <View wrap={false}>
            <View style={styles.tableContainer}>
              <View style={styles.listTableWrapper}>
                {tdItem.map((e, i) => (
                  <View style={getTableColumnTrStyle(i)}>
                    <Text style={styles.tableText}>{e}</Text>
                  </View>
                ))}
              </View>
              {newTableItem.map((e, i) => {
                let noBorder = false;
                if (i == newTableItem.length - 1) {
                  noBorder = true
                }
                        return(
                  <View style={styles.listTableWrapper}>
                    {Object.keys(e).map((keyName, i) => {
                      let style = styles.tableText;
                      let containerStyle = getTableColumnTdStyle(i, noBorder);
                      let textEmpty = false;
                      let value = e[keyName];
                      if (e[keyName] == 'Total' || e[keyName] == 'Deposit' || e[keyName] == 'Amount') {
                        style = { ...styles.tableText, textAlign: 'left'}
                      }

                      if (e[keyName] == 'NOTES' || e[keyName] == 'REMARK') {
                        containerStyle = {...styles.tableTd20, textAlign: 'left'};
                      }

                      if (`${e[keyName]}`.includes('$LONG$')) {
                        containerStyle = {...styles.tableTd60, width: 295};
                        value = `${e[keyName]}`.slice(6);
                      }

                      return(
                      <View style={ containerStyle }>
                        <Text style={style}>{textEmpty ? '' : value}</Text>
                      </View>
                    )
                  }
                    )}
                  </View>
                )
              })}
            </View>
        </View>
        <View style={styles.signContainer} fixed>
          <View style={styles.signWrapper}>
          <View style={styles.technicianWrapper}>
                <View style={styles.technicianSignArea}>
                  <View style={styles.clientLabel}>
                    <Text style={styles.contentText}> </Text>
                  </View>
                </View>
                <View>
                  <View style={styles.clientLabel}>
                    <Text style={styles.alignContentText}>FOR PACIFIC ELECTRIC SERVICES</Text>
                  </View>
                </View>
              </View>

            {/* <View style={styles.dateWrapper}>
              <View style={styles.dateInput}>
                <View style={styles.clientLabel}>
                  <Text style={styles.contentText}>COLLECT DATE</Text>
                </View>
              </View>

              <View style={styles.ownerSignBottom}>
                <View style={styles.clientLabel}>
                  <Text style={styles.contentText}>COMPLETE DATE</Text>
                </View>
              </View>
            </View> */}

            <View style={styles.clientWrapper}>
                <View style={styles.clientSign}>
                  <View style={styles.clientLabel}>
                    <Text style={styles.contentTimeText}>TIME</Text>
                  </View>
                </View>

                <View style={styles.ownerSignBottom}>
                  <View style={styles.clientLabel}>
                    <Text style={styles.contentText}>CUSTOMER SIGNATURE/COMPLETE DATE</Text>
                  </View>
                </View>
              </View>

          </View>

          <View style={styles.memoWrapper}>
            <Text style={styles.contentText}>BANK ACCOUNT: MAYBANK 505176522361</Text>
            <Text style={styles.chineseText}>
            *三个月保家（零件）     *3 Bulan Jaminan (Alat-alat Ganti)     *3 Months Warranty (Spare parts)              </Text>
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
}

export default QuotationPdf;
