import React, {useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  stepperWrapper: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
  },
  stepperButton: {
    // backgroundColor: 'black',
    '&.MuiButtonBase-root .MuiStepLabel-horizontal .MuiStepLabel-iconContainer .MuiStepIcon-root': {
      boxShadow: '2px 2px #8888883d',
      borderRadius: '200px 200px 200px 200px',
      color: '#ffffff',
      fill: theme.palette.primary.contrastText,
    },
    '&.MuiButtonBase-root .MuiStepLabel-horizontal .MuiStepLabel-iconContainer .MuiStepIcon-active': {
      fill: theme.palette.secondary.main,
    },
    '&.MuiButtonBase-root .MuiStepLabel-horizontal .MuiStepLabel-iconContainer .MuiStepIcon-text': {
      fill: theme.palette.secondary.contrastText,
    },
    '&.MuiButtonBase-root .MuiStepLabel-horizontal .MuiStepLabel-labelContainer .MuiStepLabel-label.MuiStepLabel-active': {
      color: theme.palette.secondary.main,
    },

    '&.MuiButtonBase-root .MuiStepLabel-horizontal': {
      // paddingBottom: 10,
      // borderWidth: '0 0 3px',
      // borderColor: '#000000',
      // borderStyle: 'solid',
    }
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


/**
 *
 * @param props
 * tabs (array)
 * activeStep (int)
 * handleStep (setState function)
 * @constructor
 */
export default function CustomStepper(props) {
  const {tabs, activeStep, setActiveStep} = props;
  const steps = tabs;
  const classes = useStyles();
  const [completed, setCompleted] = React.useState({});

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const handleStep = step => () => {
    setActiveStep(step);
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep} className={classes.stepperWrapper} >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton className={classes.stepperButton} onClick={handleStep(index)} completed={completed[index]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {/*<Button variant="contained" color="primary" onClick={handleComplete}>*/}
      {/*  {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}*/}
      {/*</Button>*/}
    </div>
  );
}
