import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, CircularProgress} from "@material-ui/core";

const globalWidth = 189;

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    marginRight: 6,
    paddingTop: 10,
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      flex: .35,
    },
    [theme.breakpoints.down('xs')]: {
      flex: '.5',
    },
  },
  table: {
    width: globalWidth,
    borderCollapse: 'collapse',
  },
  tableItems: {
    backgroundColor: theme.palette.background.row,
    transition: 'background-color .1s',
    '& td': {
      padding: '9px 11px',
      borderBottom: '1px solid #dddddd',
      fontSize: theme.typography.textFieldLabel.fontSize,
    },
    '&:hover': {
      // backgroundColor: '#d2d2d2',
      backgroundColor: theme.palette.background.icon,
      cursor: 'pointer',
    }
  },
  oddBackgroud: {
    backgroundColor: '#f6f8fc',
  },
  emptyRecord: {
    padding: 10,
    color: '#fd7170',
    fontSize: theme.typography.textFieldLabel.fontSize,
  },
  loaderWrapper: {
    width: globalWidth,
    textAlign: 'center',
    paddingTop: 10,
  },
  loaderIcon: {
    width: '21px !important',
    height: '21px !important',
    marginTop: 12,
  }
}));


export default function TabList(props) {
  const {title='', items=[], isLoading=false} = props;
  const classes = useStyles();

  const handleViewAction = id => () => {
    window.open(`/service/${id}`);
    // window.location.href = `/service/${id}`;
  }

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{title}</Typography>

      {isLoading ?
        <div className={classes.loaderWrapper}>
          <CircularProgress className={classes.loaderIcon} color="secondary"/>
        </div>
        :
        <table className={classes.table}>
          <tbody>
          {
            items.length > 0 ?
              items.map((items, index) => (
                <tr
                  key={items.id}
                  className={clsx(classes.tableItems)}
                  onClick={handleViewAction(items.id)}
                >
                  <td align="center" style={{color: '#007dc3'}}>{items.label}</td>
                  <td>{items.date}</td>
                  {/*<td align="end">*/}
                  {/*  <Button variant="contained" color="secondary" onClick={handleViewAction(items.id)}>*/}
                  {/*    View*/}
                  {/*  </Button>*/}
                  {/*</td>*/}
                </tr>
              ))
              :
              <tr>
                <td className={classes.emptyRecord}>No Record Was Found</td>
              </tr>
          }
          </tbody>
        </table>
      }
    </div>
  )
}
