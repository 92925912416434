import React from 'react';
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.inputs.width,
    '& .MuiInputBase-multiline': {
      padding: 10,
      fontSize: theme.typography.textFieldLabel.fontSize,
      color: theme.palette.primary.contrastText,
    },
    '& .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
      backgroundColor: theme.palette.background.selected,
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.border
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#8080804a',
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 9,
  },
  alignTop: {
    alignItems: 'baseline',
  },
  flexEnd: {
    marginTop: 0,
    justifyContent: 'space-between',
  },
  nonMargin: {
    marginTop: 0,
    justifyContent: 'start',
  },
  fullWidth: {
    width: '94%',
  },
  largeWidth: {
    width: '52%'
  },
  maxWidth: {
    maxWidth: 696,
    marginLeft: 10,
    flex: 0.97,
  },
  outlined: {
    '& .MuiInputBase-root': {
      borderRadius: 3,
      backgroundColor: theme.palette.background.selected,
    },
    '& .MuiInputBase-multiline': {
      padding: '10px 15px',
    },
  },
  smallPadding: {
    height: 25,
    '& .MuiInputBase-multiline': {
      padding: '4px 11px',
    },
  },
  fieldLabel: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    color: theme.palette.primary.contrastText,
    marginRight: 6,
    // textAlign: 'end',
  },
  fixLabel: {
    flex: .27,
    [theme.breakpoints.down('md')]: {
      flex: .44,
    },
    [theme.breakpoints.down('xs')]: {
      flex: .5,
    },
  },
}));

export default function MultilineTextField(props) {
  const {
    label, stateKey, value, placeholder, outlined, rows=1, rowsMax=3,
    maxWidth, alignTop, fullWidth, singleEl, flexEnd, nonMargin, handleChange, textLength, smallPadding
  } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.wrapper,
        alignTop && classes.alignTop,
        !singleEl && classes.flexEnd,
        nonMargin && classes.nonMargin,
        flexEnd && classes.flexEnd,
      )}
    >
      {label &&
      <Typography className={clsx(classes.fieldLabel, singleEl && classes.fixLabel)}>
        {label}
      </Typography>
      }
      <TextField
        id={label}
        className={clsx(
          classes.root,
          singleEl && classes.largeWidth,
          fullWidth && classes.fullWidth,
          outlined && classes.outlined,
          maxWidth && classes.maxWidth,
          smallPadding && classes.smallPadding
        )}
        color="secondary"
        rows={rows}
        rowsMax={rowsMax}
        variant={outlined ? 'outlined' : undefined}
        multiline
        placeholder={placeholder}
        value={value}
        onChange={handleChange(stateKey, textLength)}
      />
    </div>
  );
}
