import React, {Fragment, useCallback} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      width: '100%',
      '& .MuiInputBase-root': {
        borderRadius: 30,
        backgroundColor: theme.palette.background.selected,
        padding: '10px 25px',
      },
      '& .MuiInputBase-root .MuiInputBase-input': {
        padding: '0 10px',
      },
      '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main
      }
    },
  },
  fieldLabel: {
    color: theme.palette.primary.contrastText
  },
  textField: {
    marginBottom: 20,
  }
}));

const filter = createFilterOptions();

export default function CustomAutocomplete(props) {
  const {label, value , setState, items} = props;
  const classes = useStyles();

  const setValue = useCallback(params => {
    setState({customerName: params.name, customerId: params.id})
  }, [])

  return (
    <Autocomplete
      className={classes.root}
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            name: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={items}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(option) => option.name}
      freeSolo
      renderInput={(params) => (
        <Fragment>
          <Typography className={classes.fieldLabel}>{label}</Typography>
          <TextField
            {...params}
            className={classes.textField}
            color="secondary"
            variant="outlined"
          />
        </Fragment>
      )}
    />
  );
}
