import React, {useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';

// components
import content from "./content.json";
import MultilineTextField from "../../../components/InputFields/MultilineTextField";
import CustomAutocomplete from '../../../components/InputFields/CustomAutocomplete.js';
import CustomTextSearch from "../../../components/InputFields/CustomTextSearch"
import TabList from '../../../components/TabList';

// material ui
import {Grid} from "@material-ui/core";
import CustomSelect from "../../../components/InputFields/CustomSelect";

const useStyles = makeStyles(theme => ({
  listItem: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main
    },
  },
  relatedContainer: {
    paddingLeft: '50px !important',
  },
  relatedWrapper: {
    borderRadius: 10,
    boxShadow: '1px 1px 15px 0 rgba(72, 72, 72, 0.16)',
    padding: '35px 20px',
    backgroundColor: theme.palette.background.section
  },
}));

function Customer(props) {
  const {handleSearch, mainState, setMainState} = props;
  const classes = useStyles();

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className={classes.quotationNoWrapper}>
          {/*<CustomAutocomplete*/}
          {/*  label="Name"*/}
          {/*  stateKey="customerName"*/}
          {/*  placeholder={content.customer.field_placeholder_name}*/}
          {/*  setState={setMainState}*/}
          {/*  value={mainState.customerName}*/}
          {/*  items={mainState.customerArr}*/}
          {/*/>*/}

          <CustomSelect
            outlined
            fullWidth
            label="Name"
            stateKey="customerId"
            value={mainState.customerId}
            items={mainState.customerArr}
            handleChange={handleChange}
          />

          <div style={{marginTop: 30}} />

          <CustomTextSearch
            label={content.customer.field_2}
            placeholder={content.customer.field_placeholder_number}
            stateKey="customerPhoneNumber_01"
            value={mainState.customerPhoneNumber_01}
            handleChange={handleChange}
            handleSearch={handleSearch()}
          />

          <CustomTextSearch
            fullWidth
            outlined
            label={content.customer.field_3}
            placeholder={content.customer.field_placeholder_number}
            stateKey="customerPhoneNumber_02"
            value={mainState.customerPhoneNumber_02}
            handleChange={handleChange}
            handleSearch={handleSearch()}
          />

          <CustomTextSearch
            fullWidth
            outlined
            label={content.customer.field_4}
            placeholder={content.customer.field_placeholder_number}
            stateKey="customerPhoneNumber_03"
            value={mainState.customerPhoneNumber_03}
            handleChange={handleChange}
            handleSearch={handleSearch()}
          />

          <MultilineTextField
            fullWidth
            outlined
            label={content.customer.field_5}
            placeholder={content.customer.field_placeholder_address}
            stateKey="customerAddress"
            value={mainState.customerAddress}
            handleChange={handleChange}
            customRows={3}
          />

          <MultilineTextField
            fullWidth
            outlined
            label={content.customer.field_6}
            placeholder={content.customer.field_6}
            stateKey="customerRemarks"
            value={mainState.customerRemarks}
            handleChange={handleChange}
            customRows={3}
          />
        </Grid>

        <Grid item xs={12} md={6} className={classes.relatedContainer}>
          <div className={classes.relatedWrapper}>
            <TabList
              title={content.customer.relatedJob}
              items={mainState.relatedJob}
            />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Customer;
