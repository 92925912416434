import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '8px 0',
    '& .MuiInputBase-root .MuiInputBase-input': {
      padding: 10,
      fontSize: theme.typography.textFieldLabel.fontSize,
      fontWeight: theme.typography.texFieldValue.fontWeight,
      color: theme.palette.primary.contrastText,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      backgroundColor: theme.palette.background.selected,
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.border,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#8080804a',
    }
  },
  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: 20,
  },
  defaultWidth: {
    '& .MuiInputBase-root .MuiInputBase-input': {
      width: 167,
    }
  },
  fullWidth: {
    width: '100%',
    '& .MuiInputBase-root .MuiInputBase-input': {
      width: '100%',
    },
  },
  incline: {
    display: 'flex',
    alignItems: 'center',
  },
  inclineInput: {
    margin: 0,
    // marginLeft: 40,
  },
  inclineLabel: {
    textAlign: 'start',
  },
  outlined: {
    '& .MuiInputBase-root': {
      borderRadius: 2,
      backgroundColor: theme.palette.background.selected,
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      padding: '8px 11px',
    },
  },
  smallPadding: {
    height: 25,
     '& .MuiInputBase-root .MuiInputBase-input': {
      padding: '4px 11px',
    },
  },
  alignRight: {
    '& .MuiInputBase-root .MuiInputBase-input': {
      textAlign: 'end',
    }
  },
  fixedData: {
    '& .MuiInputBase-root .MuiInputBase-input': {
      color: theme.palette.background.fixed,
    },
  },
  fieldLabel: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    fontWeight: theme.typography.textFieldLabel.fontWeight,
    color: theme.palette.primary.contrastText,
    flex: 1,
    // textAlign: 'end',
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      flex: '.35',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '.5',
    },
  },
  labelColor: {
    color: theme.palette.secondary.main,
  }
}));

export default function CustomTextField(props) {
  const {
    label, stateKey, value, incline, placeholder='', labelColor,
    autoWidth, outlined, nonMargin, fullWidth, disabled, keyPress,
    alignRight, blurFocus, handleChange, smallPadding
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(incline && classes.incline)}>
      <Typography
        className={clsx(
          classes.fieldLabel,
          labelColor && classes.labelColor,
          incline && classes.inclineLabel,
        )}
      >
        {label}
      </Typography>
      <TextField
        id={label}
        className={clsx(
          classes.root,
          !nonMargin && classes.spacing,
          !autoWidth && classes.defaultWidth,
          incline && classes.inclineInput,
          fullWidth && classes.fullWidth,
          outlined && classes.outlined,
          disabled && classes.fixedData,
          alignRight && classes.alignRight,
          smallPadding && classes.smallPadding
        )}
        color="secondary"
        variant={outlined ? 'outlined' : undefined}
        value={value}
        placeholder={placeholder}
        disabled={disabled && !!disabled}
        onChange={handleChange(stateKey)}
        onKeyDown={keyPress && keyPress}
        onBlur={blurFocus && blurFocus}
      />
    </div>
  );
}
