import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {NavLink} from "react-router-dom";
import clsx from 'clsx';

// components
import {routes} from '../../routes';

// icons
import FontAwesomeMap from "../FontAwsomeMap";

// material ui
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';


const ExpansionPanel = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': { borderBottom: 0 },
    '&:before': { display: 'none' },
    '&$expanded': { margin: 0 },
    backgroundColor: 'transparent'
  },
  expanded: {},
}))(Accordion);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto',
    },
  },
  content: {
    color: theme.palette.primary.main,
    '&$expanded': { margin: 0 }
  },
  expanded: {},
}))(AccordionSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
    '& ul': {
      width: '100%'
    }
  },
}))(AccordionDetails);


const useStyles = makeStyles(theme => ({
  ListTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  panelText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 2),
    fontSize: theme.typography.navItem.fontSize,
  },
  panelIcon: {
    color: theme.palette.primary.main,
  },
}));

const LinkItem = withStyles(theme => ({
  noWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  active: {
    // borderLeft: `3px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    // color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.active,
    '& div': {
      color: theme.palette.primary.main,
    }
  },
  icon: {
    // fontSize: 18,
  },
  linkText: {
    justifyContent: 'center',
    transition: 'all 0.3s',
    color: theme.palette.primary.main,
    '&:hover div': {
      color: theme.palette.primary.main,
    }
  },
  titleIcon: {
    justifyContent: 'flex-start',
    minWidth: 'inherit',
    marginRight: 16,
  },
  titleText: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  typography: {
    fontSize: theme.typography.navItem.fontSize,
  },
}))((props) => {
  const { classes, text, icon, to, exact, title ,open} = props;
  const renderLink = React.forwardRef((itemProps, ref) => (
    <NavLink to={to} {...itemProps} />
  ));

  return (
    <ListItem
      className={clsx(classes.linkText)}
      component={renderLink}
      to={to}
      activeClassName={classes.active}
      exact={exact}
    >
      <ListItemIcon
        className={clsx(classes.linkText, classes.icon, title && classes.titleIcon)}>{icon}</ListItemIcon>
      {open &&
        <ListItemText
          classes={{ primary: classes.typography }}
          className={classes.noWrap}
          primary={text}
        />
      }
    </ListItem>
  );
});

const MListTitle = (props) => {
  const classes = useStyles();
  const {open, icon, title} = props;

  return (
    <React.Fragment>
      {open ?
        <div className={classes.ListTitleWrapper}>
          <FontAwesomeMap icon={icon}/>

          <Typography className={classes.panelText}>{title}</Typography>
        </div>
        :
        <FontAwesomeMap icon={icon}/>
      }
    </React.Fragment>
  )
};

function DropdownList(props) {
  const classes = useStyles();
  const { open } = props;
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];

  const NormalListItem = (props) => {
    const {item, open, permissions} = props;

    return (
      <React.Fragment>
        {( ((item.checkPermission && permissions.includes(item.path)) || !item.checkPermission ) && !item.hidden )
        && <LinkItem
              key={item.text}
              title
              exact
              open={open}
              text={item.text}
              to={item.path}
              icon={<FontAwesomeMap icon={item.icon} />}
            />
        }
      </React.Fragment>
    )
  }


  const DropListItem = (props) => {
    const {item, open, permissions} = props;

    return (
      <React.Fragment>
        {( (item.checkPermission && permissions.includes(item.path)) || !item.checkPermission ) &&
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon className={classes.panelIcon}/>}
            aria-controls="panel1a-content"
            id={item.title}
          >
            <MListTitle title={item.text} icon={item.icon} open={open}/>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              {item.children.map(list => (
                ((list.checkPermission && permissions.includes(item.path+list.path)) || (!list.hidden && !list.checkPermission))
                && <LinkItem
                  key={list.text}
                  exact
                  open={open}
                  text={list.text}
                  to={`${item.path}${list.path}`}
                  icon={<FontAwesomeMap icon={list.icon}/>}
                />
              ))}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        }
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <List>
        {routes.navBar.map((item) => (
          !item.children ?
            <NormalListItem key={item.text} item={item} open={open} permissions={permissionArray} />
            :
            <DropListItem key={item.text} item={item} open={open} permissions={permissionArray} />
        ))}
      </List>
    </React.Fragment>
  )
}

export default DropdownList;
