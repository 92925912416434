import React, {useState, useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

// components
import content from '../tabs/content.json';
import { utilsContext } from '../../../contexts/index';
import CustomStepper from '../../../components/CustomStepper.js';
import CustomHeader from "../../CommonHeader";
import {
  getCompleteStatusList,
  getSupplier,
  getCustomers,
  getInsuranceCompany,
  getQuotationCategory,
  getRepairBy, getRepairProgressStatus,
  getWarrantyTypes, getWorker, getProductBrand, getProductType
} from "../../../api/taxonomic";
import {createServices, getCustomerRelatedJob, getRepairRecord} from "../../../api/service";

// tabs
import ServiceDetails from '../tabs/ServiceDetails';
import PreDiagnose from '../tabs/PreDiagnose';
import Quotation from '../tabs/Quotation';
import Customer from '../tabs/Customer';
import RepairProgress from '../tabs/RepairProgress';
import RepairWorkflow from '../tabs/RepairWorkflow';

// material ui
import {
  Container,
  Paper,
  Button,
} from "@material-ui/core";
import CustomizeButton from "../../../components/InputFields/CustomizedButtons";

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerContentWrapper: {

  },
  headerButtonWrapper: {

  },
  pageTitle: {
    fontSize: theme.typography.pageTitleText.fontSize,
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  bodyWrapper: {
    marginTop: '-10px',
  },
  tabItemContainer: {
    marginTop: theme.spacing(5),
    minHeight: '50vh',
    backgroundColor: 'transparent'
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function CreateServices(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    // Service Details
    firstOptionNumber: '',
    secondOptionNumber: '',
    thirdOptionNumber: '',
    keyInDate: moment().format('YYYY-MM-DD'),
    jobDate: moment().format('YYYY-MM-DD'),
    purchaseDate: '',
    productType: 0,
    productBrand: 0,
    productTypeArr: [],
    productBrandArr: [],
    modelCode: '',
    serialNumber: '',
    serialNumber2: '',
    supplier: 0,
    supplierItems: [],
    purchasePrice: '',
    recentlyRepair: [],
    repairRecord: [],
    repairBy: 0,
    repairPersonArr: [],
    warrantyType: 0,
    warrantyTypeArr: [],
    refreshRecords: false,
    // Customer
    customerId: 0,
    customerName: '',
    customerPhoneNumber_01: '',
    customerPhoneNumber_02: '',
    customerPhoneNumber_03: '',
    customerAddress: '',
    customerRemarks: '',
    customerFaultyRemark: '',
    customerArr: [],
    relatedJob: [],
    // Feedback
    recentTabLoading: false,
    recordTabLoading: false,
    relatedTabLoading: false,
  });

  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleRedirect = () => {
    props.history.push(`/service`)
  }

  // API
  const handleSearchServiceDetails = async obj => {
    const failObj = {};
    const repairRecordList = [];
    const recentlyRecordList = [];

    setMainState({
      recentTabLoading: true,
      recordTabLoading: true,
    })

    try {
      const relatedListResult = await getRepairRecord({
        serialNumber:  obj.value,
        serialNumber2: obj.value2,
      });
      if (!relatedListResult.success) {throw 'Fail to get repair records'}
      relatedListResult.data.details.map(e => {
        repairRecordList.push({
          id: e.serviceId,
          label: e.jobNumber1,
          date: moment(e.keyInDate).format('DD/MM/YYYY')
        })
      })

      const recentlyListResult = await getRepairRecord({
        serialNumber: obj.value,
        serialNumber2: obj.value2,
        isRepairedIn90Day: true
      });
      if (!recentlyListResult.success) {throw 'Fail to get repair records'}
      recentlyListResult.data.details.map(e => {
        recentlyRecordList.push({
          id: e.serviceId,
          label: e.jobNumber1,
          date: moment(e.keyInDate).format('DD/MM/YYYY')
        })
      })

    } catch (err) {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = err;
    }

    setTimeout(function(){
      setMainState({
        repairRecord: repairRecordList,
        recentlyRepair: recentlyRecordList,
        recentTabLoading: false,
        recordTabLoading: false,
      })
    }, 1000)
  };

  const handleSearchCustomer = async obj => {
    setMainState({ relatedTabLoading: true });

    const customerRelatedResult = await getCustomerRelatedJob({
      phoneNumber1: obj.number,
      phoneNumber2: obj.number2,
      phoneNumber3: obj.number3,
    });

    if (customerRelatedResult.success) {
      const tmpArr = customerRelatedResult.data.details.map(e => ({
        id: e.serviceId,
        date: moment(e.keyInDate).format('YYYY-MM-DD'),
        label: e.serialNumber,
      }))

      setTimeout(function() {
        setMainState({
          relatedJob: tmpArr,
          relatedTabLoading: false,
        });
      },1000)
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: 'Fail to get related list',
      });
      setMainState({relatedTabLoading: false});
    }
  }

  const getTaxonomic = async () => {
    const supplierList = [...mainState.supplierItems];
    const repairTypeList = [...mainState.repairPersonArr];
    const warrantyTypeList = [...mainState.warrantyTypeArr];
    const customerList = [...mainState.customerArr];
    const productTypeList = [...mainState.productTypeArr]
    const productBrandList = [...mainState.productBrandArr]
    try {
      // Get Supplier List
      const supplierResult = await getSupplier();
      if (!supplierResult.success) {throw 'Fail to get supplier list'}
      supplierResult.data.details.supplier.forEach(e => {
        supplierList.push({ label: e.name, value: e.id })
      })

      // Get Company List
      const repairByResult = await getRepairBy();
      if (!repairByResult.success) {throw repairByResult.data.message}
      repairByResult.data.details.repairBy.forEach(e => {
        repairTypeList.push({ label: e.name, value: e.id })
      })

      // Get Warranty Type
      const warrantyTypeResult = await getWarrantyTypes();
      if (!warrantyTypeResult.success) {throw warrantyTypeResult.data.message}
      warrantyTypeResult.data.details.warrantyType.forEach(e => {
        warrantyTypeList.push({ label: e.name, value: e.id })
      })

      // Get Customers
      const customerResult = await getCustomers();
      if (!customerResult.success) {throw customerResult.data.message}
      customerResult.data.details.customer.forEach(e => {
        customerList.push({ label: e.name, value: e.id })
      })

      // Get Product Brand list
      const productBrandResult = await getProductBrand();
      if (!productBrandResult.success) {throw productBrandResult.data.message}
        productBrandResult.data.details.productBrand.forEach(e => {
          productBrandList.push({ label: e.name, value: e.id })
        })

      // Get Product Type list
      const productTypeResult = await getProductType();
      if (!productTypeResult.success) {throw productTypeResult.data.message}
      productTypeResult.data.details.productType.forEach(e => {
        productTypeList.push({ label: e.name, value: e.id })
      })

    } catch (err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
    }

    setMainState({
      supplierItems: supplierList,
      repairPersonArr: repairTypeList,
      warrantyTypeArr: warrantyTypeList,
      customerArr: customerList,
      productBrandArr: productBrandList,
      productTypeArr: productTypeList
    })
  }

  const handleBack = () => {
    props.history.push('/service');
  }

  const handleSubmit = async () => {
    _handleChange({ openBackdrop: true });
    const {
      firstOptionNumber, secondOptionNumber, keyInDate,
      jobDate, supplier, repairBy, productBrand, productType,
      modelCode, purchaseDate, warrantyType, purchasePrice,
      serialNumber, serialNumber2,
    } = mainState;
    const {
      customerId, customerName, customerPhoneNumber_01, customerPhoneNumber_02,
      customerPhoneNumber_03, customerAddress, customerRemarks, customerFaultyRemark
    } = mainState;
    const params = {
      service: {
        number2: firstOptionNumber,
        number3: secondOptionNumber,
        keyInDate: keyInDate ? moment(keyInDate).format('YYYY-MM-DD') : null,
        jobDate: jobDate ? moment(jobDate).format('YYYY-MM-DD') : null,
        supplierId: supplier || null,
        repairById: repairBy || null,
        productBrandId: productBrand || null,
        productTypeId: productType  || null,
        modelCode: modelCode,
        dayOfPurchase: purchaseDate === '' ? null : moment(purchaseDate).format('YYYY-MM-DD'),
        warrantyTypeId: warrantyType || null,
        purchasePrice: purchasePrice === '' ? null : purchasePrice,
        serialNumber: serialNumber,
        serialNumber2: serialNumber2
      },
      customer: {
        customerId: customerId || null,
        customerName: customerName,
        phoneNumber: customerPhoneNumber_01,
        phoneNumber2: customerPhoneNumber_02,
        phoneNumber3: customerPhoneNumber_03,
        customerAddress: customerAddress,
        faultyDescription: customerFaultyRemark,
        remark: customerRemarks
      }
    }
    console.log(params)
    const result = await createServices(params);
    const snackBarObj = {};

    if (result.success) {
      snackBarObj.snackbarType = 0;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = 'Create Successful'
    } else {
      snackBarObj.snackbarType = 1;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = result.data.message;
    }
    _handleChange({ openBackdrop: false, ...snackBarObj })
    if (result.success) {
      handleRedirect();
    }
  }

  useEffect(() => {
    async function getServicesTriggers() {
      _handleChange({ openBackdrop: true });
      await getTaxonomic();
      _handleChange({ openBackdrop: false });
    }
    getServicesTriggers();
  }, [])

  return (
    <Container fixed style={{ padding: 0 }}>
      <CustomHeader
        title={content.title}
        subTitle={content.subTitle}
      />

      <div className={classes.bodyWrapper}>
        <Paper className={classes.tabItemContainer}>
          <ServiceDetails
            mainState={mainState}
            setMainState={setMainState}
            handleSearch={handleSearchServiceDetails}
            handleCustomerSearch={handleSearchCustomer}
          />
        </Paper>

        <div className={classes.buttonWrapper}>
          <CustomizeButton active label="Create Service" handleClick={handleSubmit}/>
          <CustomizeButton label="Cancel" handleClick={handleBack}/>
        </div>
      </div>
    </Container>
  )
}

export default CreateServices;
