import React, {useContext, useCallback, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router";

// components
import { utilsContext } from '../../contexts';
import content from "./content.json";
import CustomHeader from "../CommonHeader";
import CustomTransferList from "../../components/CustomTransferList";

// material ui
import {
  Paper,
  Container,
  Button, Grid,
} from "@material-ui/core";
import CustomTextField from "../../components/InputFields/CustomTextField";
import { getRole, getPermissionList, updateRole } from '../../api/permission';
import CustomizeButton from "../../components/InputFields/CustomizedButtons";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 30,
  },
  formWrapper: {
    marginTop: 20,
    padding: 30,
    boxShadow: '0 5px 20px 0 rgba(72, 72, 72, 0.16)',
    borderRadius: 10,
    '& .MuiPaper-rounded': {
      borderRadius: 10,
    },
  },
  buttonWrapper: {
    textAlign: 'end',
    marginTop: 50,
  },
}))

function EditRole(props) {
  const classes = useStyles();
  const { roleID } = useParams();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    name: 'Admin',
    currentPermission: [
      // {id: 1, name: 'Assign Role', permissionPath: '/a'},
      // {id: 2, name: 'Assign Permission', permissionPath: '/a'},
      // {id: 3, name: 'Create Service', permissionPath: '/a'},
    ],
    permissionList: [
      // {id: 4, name: 'Edit Service Details', permissionPath: '/a'},
      // {id: 5, name: 'Edit Service Pre-Diagnose', permissionPath: '/a'},
      // {id: 6, name: 'Edit Service Quotation', permissionPath: '/a'},
      // {id: 7, name: 'Edit Service Customer', permissionPath: '/a'},
      // {id: 8, name: 'Edit Service Repair Progress', permissionPath: '/a'},
      // {id: 9, name: 'Edit Service Repair Workflow', permissionPath: '/a'},
      // {id: 10, name: 'Inspect Service', permissionPath: '/a'},
      // {id: 11, name: 'Taxonomy', permissionPath: '/a'},
    ],
  })

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const handleRolePage = () => {
    props.history.push(`/permission/role`)
  }

  // API functions
  const handleGetPermissionList = async (permissionPath) => {
    try {
      // Get Permission List
      const permissionListResult = await getPermissionList();
      if (!permissionListResult.success) {throw 'Fail to get status list'}
      let permissionListResultArray = [];
      permissionListResult.data.details.permissionList.forEach((e) => {
        const hasPermission = permissionPath.find((i) => i.id == e.id);
        if (!hasPermission) {
          let obj = {
            id: e.id,
            name: e.name,
            permissionPath: e.permissionPath,
          }
          permissionListResultArray.push(obj);
        }
      })
      setMainState({permissionList: permissionListResultArray});
    }
    catch(err) {
      console.log(err)
    }
  };

  const handleGetRole = async () => {
    try {
      // Get Role
      const obj = {
        id: roleID
      }
      const roleResult = await getRole(obj);
      if (!roleResult.success) {throw 'Fail to get role'}
      const { name, permissionPath } = roleResult.data.details.role;
      setMainState({currentPermission: permissionPath, name: name});
      await handleGetPermissionList(permissionPath);
    }
    catch(err) {
      console.log(err)
    }
  };


  const handleUpdate = async() => {
    const {currentPermission} = mainState;
    // _handleChange({openBackdrop: true});
    const permissionId = currentPermission.map((i) => {return i.id})
    try {
      // Get Role
      const obj = {
        roleId: roleID,
        name: mainState.name,
        permissionId: permissionId
      }
      const roleResult = await updateRole(obj);
      if (!roleResult.success) {throw 'Fail to update role'}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      handleRolePage();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
      console.log(err)
    }
  }

  useEffect(() => {
    // handleGetPermissionList();
    handleGetRole();
  }, [])

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader title={content.modifyRole.editTitle} />

      <Paper className={classes.formWrapper}>
        <CustomTextField
          outlined
          fullWidth
          label={content.modifyRole.inputFlied.name}
          stateKey="name"
          value={mainState.name}
          handleChange={handleChange}
        />

        <CustomTransferList
          left={mainState.permissionList}
          right={mainState.currentPermission}
          leftKey="permissionList"
          rightKey="currentPermission"
          leftTitle={content.modifyRole.listRightTitle}
          rightTitle={content.modifyRole.listLeftTitle}
          setState={setMainState}
        />

        <div className={classes.buttonWrapper}>
          <CustomizeButton label="Update" active handleClick={handleUpdate}/>
        </div>
      </Paper>
    </Container>
  )
}

export default EditRole;
