import React, {Suspense} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// components
import Navbar from "./components/NavBar";

// routes
import {routes} from './routes'

const Fallback = () => <div>Loading...</div>;

const wrapper = (Component) => (props) => {
  return <React.Fragment>
    {/*<WithTracker {...props}>*/}
    <Component {...props}/>
    {/*</WithTracker>*/}
  </React.Fragment>
};

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Switch>
          {routes.generals.map(items => (
              <Route key={items.path} exact={items.exact} path={items.path} render={wrapper(items.component)} />
          ))}

          <Navbar>
            <Switch>
              {routes.navBar.map(items => (
                items.children ?
                  items.children.map(child => (
                    <Route key={items.path+child.path} exact={child.exact} path={items.path + child.path} render={wrapper(child.component)} />
                  ))
                  :
                  <Route key={items.path} exact={items.exact} path={items.path} render={wrapper(items.component)} />
              ))}
            </Switch>
          </Navbar>

        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}
export default Router;
