import React, {useEffect, useCallback, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

// components
import content from "./content.json";
import {utilsContext} from "../../contexts";
import {getWorker} from '../../api/taxonomic.js';
import {getWorkerService} from '../../api/service.js';
import CustomHeader from "../CommonHeader";
import CustomTable from "../../components/CustomTable";
import CustomSelect from "../../components/InputFields/CustomSelect";
import CustomDatePickers from "../../components/InputFields/CustomDatePickers";
import CustomizeButton from "../../components/InputFields/CustomizedButtons";

// material ui
import {Container, Checkbox, Grid, Paper} from "@material-ui/core";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const header = [
  { id: 'id', label: 'ID', disabledSort: true },
  { id: 'number1', label: 'Job Sheet Number1', disabledSort: true },
  { id: 'number2', label: 'Job Sheet Number2', disabledSort: true },
  { id: 'serialNumber', label: 'Serial Number' },
  { id: 'keyInDate', label: 'Key In Date' },
  { id: 'amount', label: 'Total Amount' },
  { id: 'action', label: '', disabledSort: true },
]

const generateBody = (items, classes, handleRedirect) => {
  return items.map(e => ({
    id: e.serviceId,
    number1: e.number1,
    number2: e.number2,
    serialNumber: e.serialNumber,
    keyInDate: e.keyInDate,
    totalAmount: e.totalAmount,
    action: <CustomizeButton label="Edit" active handleClick={handleRedirect(e)}/>,
  }))
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    backgroundColor: theme.palette.background.section,
    padding: '20px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
  },
  filterWrapper: {
    position: 'relative',
    // backgroundColor: theme.palette.background.section,
  },
  tableWrapper: {
    marginTop: 20,
  },
  actionWrapper: {
    textAlign: 'end',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  actionButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    width: '100%',
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
  },
  checkBox: {
    '& .MuiTypography-root': {
      fontSize: theme.typography.textFieldLabel.fontSize,
    }
  },
}))

function Commission(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 0,
    rowsPerPage: 30,
    order: 'desc',
    orderBy: 'createdAT',
    startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    completeStartDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    completeEndDate: moment().format('YYYY-MM-DD'),
    workerId: 0,
    workerArr: [],
    items: [],
    completeDateState: false,
  })

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(key => async event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const handleCheck = useCallback(key => event => {
    setMainState({ [key]: event.target.checked });
  }, [])

  const handleDateChange = useCallback(key => event => {
    setMainState({ [key]: event });
  }, [])

  const handleRedirect = (items) => () => {
    props.history.push('/service/'+items.serviceId)
  }

  // API
  const handleSearch = async () => {
    _handleChange({ openBackdrop: true });

    const {
      page, rowsPerPage, startDate, endDate, workerId, completeDateState,
      completeStartDate, completeEndDate, order, orderBy
    } = mainState;

    const obj = {
      workerId,
      limit: rowsPerPage,
      offset: page,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      orderBy: orderBy,
      orderType: order
    }

    if (completeDateState) {
      obj.completeStartDate = moment(completeStartDate).format('YYYY-MM-DD');
      obj.completeEndDate = moment(completeEndDate).format('YYYY-MM-DD');
    }

    const {success, data} = await getWorkerService(obj);
    const failObj = {};
    if (success) {
      const tmpArr = data.details.serviceList.map((e, i) => ({
        serviceId: e.serviceId,
        jobDate: moment(e.jobDate).format('YYYY-MM-DD'),
        keyInDate: moment(e.keyInDate).format('YYYY-MM-DD'),
        number1: e.number1,
        number2: e.number2,
        serialNumber: e.serialNumber,
        service_id: e.service_id,
        totalAmount: e.totalAmount ? e.totalAmount.toFixed(2) : '',
      }))

      setMainState({ items: tmpArr, pageItems: data.details.totalServiceList })
    } else {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = 'Fail to get list';
    }

    _handleChange({ openBackdrop: false, ...failObj })
  }

  const getWorkerList = async () => {
    _handleChange({ openBackdrop: true });
    const {success, data} = await getWorker();

    const failObj = {};
    if (success) {
      const tmpArr = data.details.worker.map(e => ({label: e.name, value: e.id}))
      setMainState({workerArr: tmpArr})
    } else {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = data.message;
    }

    _handleChange({ openBackdrop: false, ...failObj })
  }

  useEffect(() => {
    if (mainState.workerId !== 0) {
      handleSearch(mainState.workerId);
    }
  },[mainState.page, mainState.order, mainState.orderBy])

  useEffect(() => {
    async function initialize() {
      await getWorkerList();
    }
    initialize();
  }, [])

  const body = generateBody(mainState.items, classes, handleRedirect)

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader title={content.title}/>

      <Container className={classes.contentContainer}>
        <div className={classes.filterWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomSelect
                incline
                label={content.inputField.name}
                stateKey="workerId"
                value={mainState.workerId}
                items={mainState.workerArr}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomDatePickers
                label={content.inputField.startDate}
                incline
                flexEnd
                stateKey="startDate"
                value={mainState.startDate}
                handleChange={handleDateChange}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomDatePickers
                label={content.inputField.endDate}
                incline
                stateKey="endDate"
                value={mainState.endDate}
                handleChange={handleDateChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomDatePickers
                label={content.inputField.completeStartDate}
                incline
                flexEnd
                disabled={!mainState.completeDateState}
                stateKey="completeStartDate"
                value={mainState.completeStartDate}
                handleChange={handleDateChange}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomDatePickers
                label={content.inputField.completeEndDate}
                incline
                disabled={!mainState.completeDateState}
                stateKey="completeEndDate"
                value={mainState.completeEndDate}
                handleChange={handleDateChange}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormGroup>
                <FormControlLabel
                  className={classes.checkBox}
                  control={<Checkbox checked={mainState.completeDateState} onChange={handleCheck('completeDateState')} name="completeDateState" />}
                  label="Complete Date"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <div className={classes.actionWrapper}>
            <CustomizeButton label="Search" active handleClick={handleSearch}/>
          </div>
        </div>

        <Paper className={classes.tableWrapper}>
          <CustomTable
            headCells={header}
            rows={body}
            page={mainState.page}
            pageItems={mainState.pageItems}
            rowsPerPage={mainState.rowsPerPage}
            order={mainState.order}
            orderBy={mainState.orderBy}
            setState={setMainState}
            itemAlignLeft
          />
        </Paper>
      </Container>
    </Container>
  )
}

export default Commission;
