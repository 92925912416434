import React, {useContext, useCallback, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from '@material-ui/core/styles';

// components
import { utilsContext } from '../../contexts';
import content from "./content.json";
import CustomHeader from "../CommonHeader";
import ModifyForm from "./ModifyForm";
import { getRoleList } from '../../api/permission';
import {getUser, updateOtherUserDetails, deleteUser} from '../../api/user';
import ActionDialog from "../../components/ActionDialog";

// material ui
import {
  Paper,
  Container,
  Button,
} from "@material-ui/core";
import CustomizeButton from "../../components/InputFields/CustomizedButtons";


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 30,
  },
  formWrapper: {
    marginTop: 20,
    padding: 30,
    boxShadow: '0 5px 20px 0 rgba(72, 72, 72, 0.16)',
    backgroundColor: theme.palette.background.section,
    borderRadius: 10,
    '& .MuiPaper-rounded': {
      borderRadius: 10,
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 50,
  },
}))

const statusList = [
  {value: 0, label: 'Disable'},
  {value: 1, label: 'Active'}
]

function EditUser(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const { userID } = useParams();
  const [mainState, _setMainState] = useState({
    email: '',
    username: '',
    displayName: '',
    password: '',
    roleArray: [],
    role: 10,
    status: 1,
    deleteItem: {id: '', name: ''},
  })
  const [openDialog, setOpenDialog] = useState(false);

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const handleEditAccountPage = useCallback(() => {
    props.history.push('/account/list')
  }, [])

  const handleOpenDeleteDialog = () => {
    setMainState({ deleteItem: {id: userID, name: mainState.displayName}})
    setOpenDialog(true);
  }

  // API functions
  const handleUpdate = async() => {
    try {
      // update User
      const obj = {
        userId: parseInt(userID),
        username: mainState.username,
        displayName: mainState.displayName,
        roleId: mainState.role,
        status: mainState.status
      }

      if (mainState.email) {
        obj.email = mainState.email;
      }

      if (mainState.password) {
        obj.password = mainState.password;
      }

      const userResult = await updateOtherUserDetails(obj);
      if (!userResult.success) {throw 'Fail to update user'}
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      });
      handleEditAccountPage();
    }
    catch(err) {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: err,
      });
    }
  }

  const handleDelete = async () => {
    const {success, data} = await deleteUser({ userId: userID });

    if (success) {
      _handleChange({
        snackbarType: 0,
        snackbar: true,
        snackbarContent: 'Success',
      })
      props.history.push('/account/list');
    } else {
      _handleChange({
        snackbarType: 1,
        snackbar: true,
        snackbarContent: data.message,
      })
    }
  }

  const getRoles = async() => {
    try {
      // Get Role List
      const roleListResult = await getRoleList();
      if (!roleListResult.success) {throw 'Fail to get user'}
      let roleListResultArray = [];
      roleListResult.data.details.role.forEach(e => {
        let obj = {
          value: e.id,
          label: e.name,
        }
        roleListResultArray.push(obj);
      })
      setMainState({roleArray: roleListResultArray});
    }
    catch(err) {
      console.log(err)
    }
  }

  const getUserDetails = async() => {
    try {
      // Get User
      const userResult = await getUser({userId: userID});
      if (!userResult.success) {throw 'Fail to get user'}

      let { email, displayName, userName, roleId, status } = userResult.data.details.user;
      setMainState({email, displayName, username: userName, role:roleId, status})
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getRoles();
    getUserDetails();
  },[])

  return (
    <Container fixed maxWidth="lg">
      <CustomHeader title={content.modifyAccount.editTitle} />

      <Paper className={classes.formWrapper}>
        <ModifyForm
          action={mainState.email ? 0 : 1}
          email={mainState.email}
          password={mainState.password}
          displayName={mainState.displayName}
          username={mainState.username}
          role={mainState.role}
          status={mainState.status}
          roleArray={mainState.roleArray}
          statusArray={statusList}
          handleChange={handleChange}
        />

        <div className={classes.buttonWrapper}>
          <CustomizeButton label="Delete" handleClick={handleOpenDeleteDialog}/>
          <CustomizeButton label="Update" active handleClick={handleUpdate}/>
        </div>
      </Paper>

      <ActionDialog
        open={openDialog}
        items={mainState.deleteItem}
        handleDelete={handleDelete}
        setOpen={setOpenDialog}
      />

    </Container>
  )
}

export default EditUser;
