import React, { useState } from 'react';
import moment from "moment";
import { PDFViewer, Font, Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import variables from '../configs/variables';
import font from './chinese.msyh.ttf';

Font.register({ family: 'Microsoft', src: font });

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '90vh'
  },
  page: {
    padding: '20px 20px 0',
  },
  leftImage: {
    width: 55,
    height: 55,
    marginRight: 10,
  },
  rightImage: {
    width: 190,
    height: 90,
  },
  leftHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageHeader: {
    border: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 10px',
  },
  headerContentWrapper: {
    // alignItems: 'center',
    // marginLeft: 10,
  },
  inclineText: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  tableWrapper: {
    // backgroundColor: '#e2e2e2',
    padding: 0,
    flexDirection: 'row',
    borderBottom: 1,
    borderLeft: 1,
    borderRight: 1,
  },
  col: {
    flexDirection: 'row',
    padding: '0px 3px',
    borderLeft: 0,
  },
  col2: {
    width: '450px',
    flexDirection: 'row',
    padding: '0px 3px',
    borderRight: 1,
    borderLeft: 0,
  },
  col3: {
    width: '225px',
    padding: '0px 3px',
    flexDirection: 'row',
    borderRight: 1,
  },
  colEnd: {
    width: '290px',
    padding: '0px 3px',
    flexDirection: 'row',
    borderRight: 0,
  },
  innerColumnLabel: {
    width: 100,
    padding: '5px 0px',
  },
  specialInnerColumnLabel: {
    width: 25,
    padding: '5px 0px',
  },
  innerColumnValue: {
    borderLeft: 1,
    padding: 5,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 900,
  },
  secondTitleText: {
    fontSize: 14,
    fontWeight: 900,
    marginLeft: 20,
  },
  contentText: {
    fontSize: 10,
  },
  contentTimeText: {
    fontSize: 10,
    textAlign: 'center',
  },
  chineseText: {
    fontSize: 10,
    fontFamily: 'Microsoft',
  },
  alignContentText: {
    fontSize: 10,
    textAlign: 'center',
  },
  tinyText: {
    fontSize: 6,
    marginBottom: 2,
    marginLeft: 2,
  },
  signWrapper: {
    marginTop: 50,
    flexDirection: 'row',
  },
  technicianWrapper: {
    width: 180,
  },
  newDateWrapper: {
    width: 230,
    marginLeft: 20,
  },
  dateWrapper: {
    width: 200,
    marginLeft: 20,
  },
  technicianSignArea: {
    borderBottom: 1,
  },
  dateInput: {
    borderBottom: 1,
  },
  ownerLabel: {
    width: 295,
    padding: '3px 0',
    textAlign: 'right',
  },
  clientWrapper: {
    marginLeft: 70,
  },
  clientSign: {
    borderBottom: 1,
    width: 330,
  },
  clientLabel: {
    padding: '3px 0',
  },
  memoWrapper: {
    marginTop: 20,
  },
  signContainer: {
    display: 'fixed',
    bottom: 0
  }
});

function QuotationPdf(props) {
  const { details, pdf, serviceID } = props;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  let supplierValue, warrantyValue, productTypeValue, productBrandValue;

  details.supplierItems.forEach(e => {
    if (e.value === details.supplier) supplierValue = e.label;
  })

  details.warrantyTypeArr.forEach(e => {
    if (e.value === details.warrantyType) warrantyValue = e.label;
  })

  details.productTypeArr.forEach(e => {
    if (e.value === details.productType) productTypeValue = e.label;
  })

  details.productBrandArr.forEach(e => {
    if (e.value === details.productBrand) productBrandValue = e.label;
  })


  return (
    // <PDFViewer style={styles.container}>
    <Document
      file={pdf}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.imageHeader}>
          <View style={styles.leftHeaderWrapper}>
            <Image
              style={styles.leftImage}
              src={`${variables.siteUrl}/pacific-logo.png`}
            />

            <View style={styles.headerContentWrapper}>
              <View style={styles.inclineText}>
                <Text style={styles.titleText}>PACIFIC ELECTRIC SERVICES</Text>
                <Text style={styles.tinyText}>(NS0197563-W)</Text>
              </View>
              <Text style={styles.contentText}>42, JLN S2 D37, City Centre, Seremban 2, 70300 Seremban, Negeri Sembilan.</Text>
              <View style={styles.inclineText}>
                <Text style={styles.titleText}>H/P: 012-676 5577 / 012-676 5958</Text>
                <Text style={styles.secondTitleText}>Tel: 06-603 1688</Text>
              </View>
              <Text style={styles.contentText}>pacificservies@outlook.my</Text>
            </View>
          </View>

          <Image
            style={styles.rightImage}
            src={`${variables.siteUrl}/combine-img.png`}
          />
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>SUPPLIER</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{supplierValue}</Text>
            </View>
          </View>

          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>PHOTO</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> </Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>DATE</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.jobDate ? moment(details.jobDate).format('DD-MM-YYYY') : ''}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>JOB NUM 2</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.firstOptionNumber}</Text>
            </View>
          </View>

          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>JOB NUM 3</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.secondOptionNumber}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>JOB NUM 1</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.jobNumber}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>NAME</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerName}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>DOP</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.purchaseDate ? moment(details.purchaseDate).format('DD-MM-YYYY') : ''}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>ADDRESS</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerAddress.replace(/(\r\n|\n|\r)/gm, "")}</Text>
            </View>
          </View>

          {/*<View style={styles.colEnd}>*/}
          {/*  <View style={styles.innerColumnLabel}>*/}
          {/*    <Text style={styles.contentText}>JS NO 2</Text>*/}
          {/*  </View>*/}
          {/*  <View style={styles.innerColumnValue}>*/}
          {/*    <Text style={styles.contentText}> {details.firstOptionNumber}</Text>*/}
          {/*  </View>*/}
          {/*</View>*/}
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>TEL NUM 1</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerPhoneNumber_01}</Text>
            </View>
          </View>

          <View style={styles.col3}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>TEL NUM 2</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerPhoneNumber_02}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>TEL NUM 3</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.customerPhoneNumber_03}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>COMPLAIN</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{details.customerFaultyRemark}</Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>PRODUCT</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {productTypeValue}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>COLLECT PART</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> </Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>W/TYPE</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {warrantyValue}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.specialInnerColumnLabel}>
              <Text style={styles.contentText}>1</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> </Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>MAKER</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{productBrandValue}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.specialInnerColumnLabel}>
              <Text style={styles.contentText}>2</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> </Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>MODEL</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> {details.modelCode}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.specialInnerColumnLabel}>
              <Text style={styles.contentText}>3</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> </Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>S/N NO</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{details.serialNumber}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.specialInnerColumnLabel}>
              <Text style={styles.contentText}>4</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> </Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>CHARGES</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}></Text>
            </View>
          </View>
        </View>

        <View style={styles.tableWrapper}>
          <View style={styles.col2}>
            <View style={styles.specialInnerColumnLabel}>
              <Text style={styles.contentText}>5</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}> </Text>
            </View>
          </View>

          <View style={styles.colEnd}>
            <View style={styles.innerColumnLabel}>
              <Text style={styles.contentText}>REMARK</Text>
            </View>
            <View style={styles.innerColumnValue}>
              <Text style={styles.contentText}>{details.customerRemarks}</Text>
            </View>
          </View>
        </View>

        <View style={styles.signContainer}>
          <View style={styles.signWrapper}>
            <View style={styles.newDateWrapper}>
            <View style={styles.dateInput}>
                <View style={styles.clientLabel}>
                  <Text style={styles.contentTimeText}>TIME</Text>
                </View>
              </View>
              <View>
                <View style={styles.clientLabel}>
                  <Text style={styles.contentText}>TECHNICIAN SIGNATURE/COLLECT DATE</Text>
                </View>
              </View>
            </View>

            <View style={styles.newDateWrapper}>
              <View style={styles.dateInput}>
                <View style={styles.clientLabel}>
                  <Text style={styles.contentTimeText}>TIME</Text>
                </View>
              </View>

              <View style={styles.ownerSignBottom}>
                <View style={styles.clientLabel}>
                  <Text style={styles.contentText}>CUSTOMER SIGNATURE/COLLECT DATE</Text>
                </View>
              </View>
            </View>

            <View style={styles.newDateWrapper}>
              <View style={styles.dateInput}>
                <View style={styles.clientLabel}>
                  <Text style={styles.contentTimeText}>TIME</Text>
                </View>
              </View>

              <View style={styles.ownerSignBottom}>
                <View style={styles.clientLabel}>
                  <Text style={styles.contentText}>CUSTOMER SIGNATURE/COMPLETE DATE</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.memoWrapper}>
            <Text style={styles.contentText}>BANK ACCOUNT: MAYBANK 505176522361</Text>
            <Text style={styles.chineseText}>
              注意: 修理物件, 期限二个月内取货, 过期作废。 NOTE: Goods repaired to be collect within 2 month, otherwise no valid
          </Text>
            <Text style={styles.contentText}>Tidak tanggungjawab kalau barang-barang tidak diambil dalam tempoh dua bulan</Text>
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
}

export default QuotationPdf;
