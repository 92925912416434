import React, {useContext, useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

// components
import content from "./content.json";
import CustomDatePickers from "../../../components/InputFields/CustomDatePickers";
import CustomTextField from "../../../components/InputFields/CustomTextField";
import CustomSelect from "../../../components/InputFields/CustomSelect";
import NumberInput from "../../../components/InputFields/NumberInput";
import TabList from "../../../components/TabList";

// material ui
import {
  Container,
  Grid,
} from "@material-ui/core";
import MultilineTextField from "../../../components/InputFields/MultilineTextField";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    backgroundColor: theme.palette.background.section,
    padding: 12,
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(94, 94, 94, 0.16)',
    marginBottom: 15,
  },
  listItem: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main
    },
  },
  listWrapper: {
    marginTop: 20,
    borderRadius: 10,
    boxShadow: '1px 1px 15px 0 rgba(72, 72, 72, 0.16)',
    padding: '35px 20px',
    backgroundColor: theme.palette.background.section
  },
  //  common
  spacer: {
    marginTop: 20,
  },
  displayFlex: {
    display: 'flex',
  },
  flexJustifyCenter: {
    justifyContent: 'center'
  },
  flexJustifyBetween: {
    justifyContent: 'space-between'
  },
  flexAlignCenter: {
    alignItems: 'center'
  },
  flexStart: {
    justifyContent: ''
  }
}));

function ServiceDetails(props) {
  const {serviceID, mainState, setMainState, handleSearch, handleCustomerSearch} = props;
  const classes = useStyles();
  let timeout;

  const handleChange = useCallback((key,length) => event => {
    if (length && event.target.value.length >= length)  {
      return;
    }
    setMainState({ [key]: event.target.value });
  }, [])

  const handleNumberTextFieldChange = useCallback(target => {
    const {name, value} = target.target;
    setMainState({ [name]: value });
  }, [])

  const handleDateChange = key => event => {
    setMainState({ [key]: event });
  }

  const handleSetTimeout = useCallback( (func, obj) => {
    timeout = setTimeout(function(){
      func(obj);
      }, 1500);
  }, [])

  const handleClearTimeout = useCallback(() => {
    clearTimeout(timeout);
  }, []);

  const handleSearchSerialChange = key => event => {
    const {serialNumber, serialNumber2} = mainState;
    let searchValue, searchValue2;
    if (key === 'serialNumber') {
      searchValue = event.target.value;
      searchValue2 = serialNumber2;
    } else {
      searchValue = serialNumber;
      searchValue2 = event.target.value;
    }

    handleClearTimeout();
    handleSetTimeout(handleSearch, { value: searchValue, value2: searchValue2 });
    setMainState({ [key]: event.target.value });
  }

  const handleSearchRelatedChange = key => event => {
    const {customerId, customerPhoneNumber_01, customerPhoneNumber_02, customerPhoneNumber_03} = mainState;
    const value = event.target.value;
    const obj = { id: customerId }
    if (key === 'customerPhoneNumber_01') {
      obj.number = value;
      obj.number2 = customerPhoneNumber_02;
      obj.number3 = customerPhoneNumber_03;
    } else if (key === 'customerPhoneNumber_02') {
      obj.number = customerPhoneNumber_01;
      obj.number2 = value;
      obj.number3 = customerPhoneNumber_03;
    } else if (key === 'customerPhoneNumber_03') {
      obj.number = customerPhoneNumber_01;
      obj.number2 = customerPhoneNumber_02;
      obj.number3 = value;
    }

    handleClearTimeout();
    handleSetTimeout(handleCustomerSearch, obj);
    setMainState({ [key]: event.target.value });
  }

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      {/* Services Details */}
      <Container  className={classes.contentContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomTextField
              incline
              outlined
              disabled
              placeholder="Running Number"
              label={content.serviceDetails.field_1}
              stateKey="jobNumber"
              value={mainState.jobNumber}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomDatePickers
              label={content.serviceDetails.field_4}
              incline
              flexEnd
              disabled
              stateKey="keyInDate"
              value={mainState.keyInDate}
              handleChange={handleDateChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomTextField
              incline
              outlined
              label={content.serviceDetails.field_2}
              placeholder={'Enter ' + content.serviceDetails.field_2}
              stateKey="firstOptionNumber"
              value={mainState.firstOptionNumber}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomDatePickers
              label={content.serviceDetails.field_5}
              incline
              clearable
              flexEnd
              stateKey="jobDate"
              value={mainState.jobDate}
              handleChange={handleDateChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomTextField
              incline
              outlined
              label={content.serviceDetails.field_3}
              placeholder={'Enter ' + content.serviceDetails.field_3}
              stateKey="secondOptionNumber"
              value={mainState.secondOptionNumber}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomSelect
              incline
              label={content.serviceDetails.field_13}
              stateKey="supplier"
              value={mainState.supplier}
              items={mainState.supplierItems}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomSelect
              incline
              label={content.serviceDetails.field_10}
              stateKey="repairBy"
              value={mainState.repairBy}
              items={mainState.repairPersonArr}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Container>

      {/* Product */}
      <Container  className={classes.contentContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
              <CustomSelect
                incline
                label={content.serviceDetails.field_7}
                stateKey="productBrand"
                value={mainState.productBrand}
                items={mainState.productBrandArr}
                handleChange={handleChange}
              />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomDatePickers
              label={content.serviceDetails.field_15}
              customStyle={mainState.purchaseDate ? {} : {color: 'white'}}
              incline
              clearable
              flexEnd
              stateKey="purchaseDate"
              value={mainState.purchaseDate}
              handleChange={handleDateChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomSelect
              incline
              label={content.serviceDetails.field_6}
              stateKey="productType"
              value={mainState.productType}
              items={mainState.productTypeArr}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomSelect
              incline
              label={content.serviceDetails.field_12}
              stateKey="warrantyType"
              value={mainState.warrantyType}
              items={mainState.warrantyTypeArr}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomTextField
              incline
              outlined
              label={content.serviceDetails.field_8}
              placeholder={'Enter ' + content.serviceDetails.field_8}
              stateKey="modelCode"
              value={mainState.modelCode}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <NumberInput
              outlined
              label={content.serviceDetails.field_11}
              value={mainState.purchasePrice}
              stateKey="purchasePrice"
              handleChange={handleNumberTextFieldChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <CustomTextField
              incline
              outlined
              label={content.serviceDetails.field_9}
              placeholder={'Enter ' + content.serviceDetails.field_9}
              stateKey="serialNumber"
              value={mainState.serialNumber}
              handleChange={handleSearchSerialChange}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomTextField
              incline
              outlined
              label={content.serviceDetails.field_16}
              placeholder={'Enter ' + content.serviceDetails.field_16}
              stateKey="serialNumber2"
              value={mainState.serialNumber2}
              handleChange={handleSearchSerialChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <TabList
              title={content.serviceDetails.recentRepair}
              items={mainState.recentlyRepair}
              isLoading={mainState.recentTabLoading}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TabList
              title={content.serviceDetails.repairRecord}
              items={mainState.repairRecord}
              isLoading={mainState.recordTabLoading}
            />
          </Grid>
        </Grid>
      </Container>

      {/* Customer */}
      <Container  className={classes.contentContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>

            <Grid container spacing={2}>
              {/*<Grid item xs={12}>*/}
              {/*  <CustomSelect*/}
              {/*    incline*/}
              {/*    label={content.customer.field_1}*/}
              {/*    stateKey="customerId"*/}
              {/*    value={mainState.customerId}*/}
              {/*    items={mainState.customerArr}*/}
              {/*    handleChange={handleChange}*/}
              {/*  />*/}
              {/*</Grid>   */}
              <Grid item xs={12}>
                <CustomTextField
                  incline
                  outlined
                  label={content.customer.field_1}
                  placeholder={'Enter ' + content.customer.field_1}
                  stateKey="customerName"
                  value={mainState.customerName}
                  handleChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  incline
                  outlined
                  label={content.customer.field_2}
                  placeholder={'Enter ' + content.customer.field_2}
                  stateKey="customerPhoneNumber_01"
                  value={mainState.customerPhoneNumber_01}
                  handleChange={handleSearchRelatedChange}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  incline
                  outlined
                  label={content.customer.field_3}
                  placeholder={'Enter ' + content.customer.field_3}
                  stateKey="customerPhoneNumber_02"
                  value={mainState.customerPhoneNumber_02}
                  handleChange={handleSearchRelatedChange}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  incline
                  outlined
                  label={content.customer.field_4}
                  placeholder={'Enter ' + content.customer.field_4}
                  stateKey="customerPhoneNumber_03"
                  value={mainState.customerPhoneNumber_03}
                  handleChange={handleSearchRelatedChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4}>
            <TabList
              title={content.customer.relatedJob}
              items={mainState.relatedJob}
              isLoading={mainState.relatedTabLoading}
            />
          </Grid>
        </Grid>
            <MultilineTextField
              outlined
              singleEl
              label={content.customer.field_5}
              placeholder={content.customer.field_placeholder_address}
              stateKey="customerAddress"
              value={mainState.customerAddress}
              handleChange={handleChange}
              rows={1}
            />
            <MultilineTextField
              outlined
              singleEl
              label={content.customer.field_7}
              placeholder={'Enter ' + content.customer.field_7}
              stateKey="customerFaultyRemark"
              value={mainState.customerFaultyRemark}
              textLength={60}
              handleChange={handleChange}
              customRows={3}
            />
            <MultilineTextField
              outlined
              singleEl
              label={content.customer.field_6}
              placeholder={'Enter ' + content.customer.field_6}
              stateKey="customerRemarks"
              value={mainState.customerRemarks}
              textLength={80}
              handleChange={handleChange}
              customRows={3}
            />

      </Container>


    </Container>
  )
}

export default ServiceDetails;
