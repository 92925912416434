import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 30,
    marginTop: theme.spacing(1),
    marginBottom: 25,

    '&:focus': {
      borderColor: 'red',
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  fieldLabel: {
    fontSize: theme.typography.textFieldLabel.fontSize,
    color: theme.palette.primary.contrastText
  },
}));

export default function CustomTextSearch(props) {
  const {label, value, stateKey, placeholder, handleChange, handleSearch} = props;
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.fieldLabel}>{label}</Typography>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder={placeholder}
          value={value}
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={handleChange(stateKey)}
        />
        <IconButton className={classes.iconButton} aria-label="search" onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
}
