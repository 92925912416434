import React, {useContext, useEffect, useCallback, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

// components
import { utilsContext } from '../../contexts';
import content from "./content.json";
import {getUser, updateUserDetails, updateUserPassword} from "../../api/user.js";
import CustomHeader from "../CommonHeader";
import CustomTextField from "../../components/InputFields/CustomTextField";
import CustomPasswordField from "../../components/InputFields/CustomPasswordField";

// material ui
import {
  Grid,
  Paper,
  Container,
  Button
} from "@material-ui/core";
import CustomizeButton from "../../components/InputFields/CustomizedButtons";

const useStyles = makeStyles(theme => ({
  container: {
    // marginTop: 30,
  },
  sectionWrapper: {
    padding: 30,
    borderRadius: 10,
    boxShadow: '1px 1px 15px 0 rgba(72, 72, 72, 0.16)',
    backgroundColor: theme.palette.background.section,
  },
  buttonWrapper: {
    textAlign: 'end',
  },
  submitButton: {
    color: '#ffffff',
    borderRadius: 100,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: 'linear-gradient(to right, #007dc3, #0d88da)',
    width: 175,
    marginTop: 50,
  }
}))

function UserDetails() {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    role: '',
    email: '',
    username: '',
    displayName: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    invalidPassword: false,
    disableUpdatePassword: true,
  })

  const setMainState = useCallback((newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(key => event => {
    setMainState({ [key]: event.target.value });
  }, [])

  const handlePasswordChange = useCallback(key => event => {
    const {newPassword} = mainState;
    setMainState({ [key]: event.target.value });
    if (newPassword !== event.target.value) {
      setMainState({
        invalidPassword: "Password doesn't match",
        disableUpdatePassword: true,
      })
    } else {
      setMainState({
        invalidPassword: false,
        disableUpdatePassword: false,
      })
    }
  }, [mainState.newPassword])

  // API
  const handleGetUserDetails = async () => {
    _handleChange({ openBackdrop: true });
    const uid = localStorage.getItem("userID");
    const {success, data} = await getUser({ userId: uid });

    const failObj = {};
    if (success) {
      const e = data.details.user;
      setMainState({
        role: e.roleName,
        email: e.email,
        username: e.userName,
        displayName: e.displayName
      })
    } else {
      failObj.snackbarType = 1;
      failObj.snackbar = true;
      failObj.snackbarContent = data.message;
    }

    _handleChange({ openBackdrop: false, ...failObj })
  }

  const handleUpdateDetails = async () => {
    _handleChange({ openBackdrop: true });
    const {displayName, username} = mainState;
    const uid = localStorage.getItem("userID");
    const {success, data} = await updateUserDetails({
      userId: uid,
      displayName: displayName,
      username: username,
    });

    const snackBarObj = {};
    if (success) {
      localStorage.setItem("userName", displayName);
      snackBarObj.snackbarType = 0;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = 'Update Successful'
    } else {
      snackBarObj.snackbarType = 1;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = data.message;
    }

    _handleChange({ openBackdrop: false, ...snackBarObj })
  }

  const handleUpdatePassword = async () => {
    _handleChange({ openBackdrop: true })
    const {oldPassword, newPassword, confirmNewPassword} = mainState;

    if (oldPassword === '' ||  newPassword === '' || confirmNewPassword === '') {
      setMainState({
        invalidPassword: "Password cannot be blank",
        disableUpdatePassword: true,
      })
    }

    const uid = localStorage.getItem("userID");
    const {success, data} = await updateUserPassword({
      userId: uid,
      currentPassword: oldPassword,
      newPassword: newPassword,
    })

    const snackBarObj = {};
    if (success) {
      snackBarObj.snackbarType = 0;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = 'Update Successful'

      setMainState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        disableUpdatePassword: true,
      })
    } else {
      snackBarObj.snackbarType = 1;
      snackBarObj.snackbar = true;
      snackBarObj.snackbarContent = data.message;
    }

    _handleChange({ openBackdrop: false, ...snackBarObj })
  }

  useEffect(() => {
    async function initialize() {
      await handleGetUserDetails();
    }
    initialize();
  }, [])

  return (
    <Container fixed maxWidth="lg" style={{ padding: 0 }}>
      <CustomHeader title={content.accountDetails.title} />

      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.sectionWrapper}>
            <CustomTextField
              outlined
              disabled
              fullWidth
              label={content.accountDetails.role}
              stateKey="displayName"
              value={mainState.role}
              handleChange={handleChange}
            />

            {/*<CustomTextField*/}
            {/*  outlined*/}
            {/*  disabled*/}
            {/*  fullWidth*/}
            {/*  label={content.accountDetails.email}*/}
            {/*  stateKey="email"*/}
            {/*  value={mainState.email}*/}
            {/*  handleChange={handleChange}*/}
            {/*/>*/}

            <CustomTextField
              outlined
              disabled
              fullWidth
              label={content.accountDetails.username}
              stateKey="Username"
              value={mainState.username}
              handleChange={handleChange}
            />

            <CustomTextField
              outlined
              fullWidth
              label={content.accountDetails.inputFlied.displayName}
              stateKey="displayName"
              value={mainState.displayName}
              handleChange={handleChange}
            />

            <div className={classes.buttonWrapper}>
              <CustomizeButton
                active
                label="Update Details"
                handleClick={handleUpdateDetails}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper className={classes.sectionWrapper}>
            <CustomPasswordField
              fullWidth
              label={content.accountDetails.inputFlied.oldPassword}
              stateKey="oldPassword"
              value={mainState.oldPassword}
              handleChange={handleChange}
            />

            <CustomPasswordField
              fullWidth
              label={content.accountDetails.inputFlied.newPassword}
              stateKey="newPassword"
              value={mainState.newPassword}
              handleChange={handleChange}
            />

            <CustomPasswordField
              fullWidth
              label={content.accountDetails.inputFlied.confirmNewPassword}
              stateKey="confirmNewPassword"
              value={mainState.confirmNewPassword}
              handleChange={handlePasswordChange}
              error={mainState.invalidPassword}
            />

            <div className={classes.buttonWrapper}>
              <CustomizeButton
                active
                disabled={!!mainState.disableUpdatePassword}
                label="Update Password"
                handleClick={handleUpdatePassword}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserDetails;
