import { useMemo, useCallback } from 'react';

function useLocalQuery() {
  const items = localStorage.getItem("handleSearchParam");
  const localStorageItem = items ? JSON.parse(items) : {};
  const currentLocalState =  useMemo(() => localStorageItem, [localStorageItem]);

  const setLocalState = useCallback((newState={}) => {
    localStorage.setItem("handleSearchParam", JSON.stringify(newState));
  }, [])

  return [currentLocalState, setLocalState];
}

export default useLocalQuery;
